import Http from '../../Globals/Http';


const get = {
	code		: async (code)	=> await Http.request(`helpdesk/${code}`)
};


export {
	get
};