import React, {useEffect,useState} from 'react';

// plug-ins
import moment from 'moment';
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import LoaderInline from '../../../Components/LoaderInline';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';

// models
import {Clients, LoyalityBonuses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';
import Orfo from '../../../Globals/Orfo';

// constants
import { transactionTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgFilterButton from './Images/filter-btn.svg';


// start
const LoyalityBonusesScreen = () => {
	const [ModalClients, modalClientsOpen, modalClientsClose] = useModal('root');
	const [ModalClientsList, modalClientsListOpen, modalClientsListClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [loadinginner, setLoadinginner] = useState(null);
	const [transactions, setTransactions] = useState(null);
	const [transactionsAll, setTransactionsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:amount, setValue:setAmount, bind:bindAmount} = useInput('');
	const {value:comment, setValue:setComment, bind:bindComment} = useInput('');
	const {value:phones, setValue:setPhones, bind:bindPhones} = useInput('');
	const {value:days, setValue:setDays, bind:bindDays} = useInput('30');
	const [createdAt, setCreatedAt] = useState('');
	const [tranClients, setTranClients] = useState([]);
	const [clients, setClients] = useState([]);
	const [clientsAll, setClientsAll] = useState([]);
	const [type, setType] = useState('');
	const [search, setSearch] = useState('');
	const [clientSearch, setClientSearch] = useState('');
	const [filterNewClients, setFilterNewClients] = useState(false);
	const [filterMonthActive, setFilterMonthActive] = useState(false);
	const [filterOldClients, setFilterOldClients] = useState(false);
	const [filterLastMonthActive, setFilterLastMonthActive] = useState(false);
	const {value:filterAmountMore, setValue:setFilterAmountMore, bind:bindFilterAmountMore} = useInput('');
	const {value:filterAmountLess, setValue:setFilterAmountLess, bind:bindFilterAmountLess} = useInput('');
	const {value:filterCountMore, setValue:setFilterCountMore, bind:bindFilterCountMore} = useInput('');
	const {value:filterCountLess, setValue:setFilterCountLess, bind:bindFilterCountLess} = useInput('');
	const [isFilterShow, setIsFilterShow] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const transactions = await LoyalityBonuses.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setTransactions(transactions);
		setTransactionsAll(transactions);
	};
	const transactionAdd = () => show();
	const transactionShow = (transaction) => show(transaction);
	const show = (transaction) => {
		setId(transaction?transaction._id:null);
		setAmount(transaction?transaction.amount:'');
		setComment(transaction?transaction.comment:'');
		setPhones(transaction?transaction.phones:'');
		setDays(transaction?transaction.properties.days:'30');
		setType(transaction?transaction.type:'');
		setTranClients(transaction?transaction.clients:[]);
		setCreatedAt(transaction?transaction.createdAt:'');
	};
	const cancel = () => {
		transactionShow(null);
		modalClientsHide();
		modalClientsListClose();
		filterReset();
	};
	const modalClientsHide = () => {
		setClients([]);
		modalClientsClose();
	};
	const save = async () => {
		const p = tranClients.map(v => v.phone);
		const data = {
			amount:amount,
			comment:comment,
			phones:`${phones}`,
			days:days,
			type:type,
		};
		if (Utils.empty(data.amount)) {
			errorShow('Необходимо заполнить сумму');
			return;
		}
		const res = await LoyalityBonuses.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			loyalitiesGet();
			cancel();
			return true;
		}
	}
	const searchTransactions = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTransactions(transactionsAll);
		else setTransactions(transactionsAll.filter(f => f.phone.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	}
	const handleType = (e) => setType(parseInt(e.target.value));
	const searchClient = async (e) => {
		setLoadinginner(true);
		const search = e.target.value;
		if (!Utils.empty(search)) {
			const filter = filterCollect();
			const clients = await Clients.get.search(search, filter).catch((ex) => console.log(666, ex));
			setClientsAll(clients||[]);
		}
		setClientSearch(search);
		setLoadinginner(false);
	};
	const clientSelect = (client) => {
		if (clients.find(f => f._id === client._id)) setClients(clients.filter(f => f._id !== client._id));
		else setClients([...clients,client]);
	};
	const clientAdd = () => {
		const phones = clients.map(v => v.phone);
		setPhones(phones.join(','));
		setTranClients(clients);
		modalClientsHide();
	};
	const clientsShow = async () => {
		setClients(tranClients);
		setClientsAll([]);
		modalClientsOpen();
	};
	const clientsListShow = () => modalClientsListOpen();
	const isSelect = (phone) => {
		const p = phones.split(',');
		return p.find(f => Utils.phoneNormalize(f) === Utils.phoneNormalize(phone)) || clients.find(f => Utils.phoneNormalize(f.phone) === Utils.phoneNormalize(phone));
	};
	const filterShow = () => setIsFilterShow(!isFilterShow);
	const filterHide = () => setIsFilterShow(false);
	const filterReset = () => {
		setFilterNewClients(false);
		setFilterMonthActive(false);
		setFilterOldClients(false);
		setFilterLastMonthActive(false);
		setFilterAmountMore('');
		setFilterAmountLess('');
		setFilterCountMore('');
		setFilterCountLess('');
		setClientsAll([]);
	};
	const filterApply = async () => {
		setIsFilterShow(false);
		setLoadinginner(true);
		const filter = filterCollect();
		const clients = await Clients.get.search(clientSearch, filter).catch((ex) => console.log(666, ex));
		setClientsAll(clients||[]);
		setLoadinginner(false);
	};
	const filterCollect = () => {
		const filter = {};
		if (filterNewClients) filter.newClients = true;
		if (filterMonthActive) filter.monthActive = true;
		if (filterOldClients) filter.oldClients = true;
		if (filterLastMonthActive) filter.lastMonthActive = true;
		if (filterAmountMore) filter.amountMore = filterAmountMore;
		if (filterAmountLess) filter.amountLess = filterAmountLess;
		if (filterCountMore) filter.countMore = filterCountMore;
		if (filterCountLess) filter.countLess = filterCountLess;
		return Object.keys(filter).length ? filter : null;
	};
	const filterCount = () => {
		const filter = filterCollect();
		if (!filter) return null;
		let count = 0;
		Object.keys(filter).map(v => {
			if (filter[v]) count++;
		});
		return count ? <span className="count">{count}</span> : null;
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = transactions.find(f => f._id === v);
			await loyalityCopy(s, true, true);
		};
		successShow('Операция прошла успешно');
		loyalitiesGet();
		cancel();
		return true;
	};
	const loyalityCopy = async (s, norelaod, noerrorshow) => {
		if (s) {
			const data = {
				amount:s.amount,
				comment:s.comment,
				phones:s.clients.map(v => v.phone).join(','),
				days:s.properties.days,
				type:s.type
			};
			const res = await LoyalityBonuses.add(data).catch((ex) => {
				console.log(666, ex);
				if (!noerrorshow)
					errorShow();
			});
			if (res) {
				successShow(res.message);
				if (!norelaod) {
					loyalitiesGet();
					cancel();
				}
				return true;
			}
		}
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Бонусы"
							type="marketing"
							data={transactions}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'createdAt',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Тип',field:'status',class:'status',func:(v) => <div className={`status${v.type-1}`}>{transactionTypeName[v.type]}</div>}
							]}
							rowControlItems={[
								{title:'Копировать',class:'copy',action:loyalityCopy}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy}
							]}
							empty={<>Добавьте бонусы<br/>программы лояльности</>}
							contentShowTitle={moment(createdAt).format('DD.MM.YYYY HH:mm')}
							contentShow={<>
								{comment ? 
									<div className="product-view-row-simple">
										{comment}
									</div> : null}
								<h4>Клиенты</h4>
								<div className="product-edit-row">
									<div className="product-edit-btn" onClick={() => clientsListShow()}>Список клиентов ({tranClients.length})</div>
								</div>
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Бонусов</span>
									<div>{amount?`${amount} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Действует в течении</span>
									<div>{Orfo.counters.days(days)}</div>
								</div>
							</>}
							contentEdit={<>
								<div className="product-edit-row product-edit-row-first">
									<div className="select select-wide">
										<select onChange={handleType} value={type} required>
											<option value="">Выберите тип</option>
											{transactionTypeName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Комментарий для клиента" {...bindComment}></textarea>
								</div>
								<h4>Клиенты</h4>
								<div className="product-edit-row">
									<textarea placeholder="Телефоны через запятую" {...bindPhones}></textarea>
								</div>
								<div className="product-edit-row">
									<div className="product-edit-btn" onClick={() => clientsShow()}>Добавить клиентов</div>
								</div>
								<h4>Правила</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="bonuses" className="label-middle">Кол-во бонусов</label>
									<input id="bonuses" {...bindAmount} type="text" placeholder="0" maxLength={5} required />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="bonuses" className="label-middle">
										Время действия, дней
										<span>по умолчанию 30 дней</span>
									</label>
									<input id="bonuses" {...bindDays} type="text" placeholder="0" maxLength={3} required />
								</div>
							</>}
							onAdd={transactionAdd}
							onSave={save}
							onSearch={searchTransactions}
							onShow={transactionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalClients>
				<div className="modal modal-clients">
					<div className="header">
						<h4>Добавить клиента</h4>
						<img src={imgClose} alt="" onClick={modalClientsHide} className="btn-close" />
					</div>
					<div className="search-block">
						<input type="search" placeholder="Поиск" className="search" onChange={searchClient} autoFocus={true} />
						<div className="filter-button" onClick={filterShow}>
							<img src={imgFilterButton} alt="" />
							{isFilterShow ? null : filterCount()}
						</div>
						{isFilterShow ?
							<div className="filter">
								<h4>Фильтр</h4>
								<img src={imgClose} alt="" className="close" onClick={filterHide} />
								<div className="filter-inner">
									<div className="filter-column">
										<div className="filter-row">
											<label>Новые клиенты</label>
											<img src={filterNewClients ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFilterNewClients(!filterNewClients)} />
										</div>
										<div className="filter-row">
											<label>Активные в этом месяце</label>
											<img src={filterMonthActive ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFilterMonthActive(!filterMonthActive)} />
										</div>
									</div>
									<div className="filter-column">
										<div className="filter-row">
											<label>Более месяца</label>
											<img src={filterOldClients ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFilterOldClients(!filterOldClients)} />
										</div>
										<div className="filter-row">
											<label>Активные в прошлом месяце</label>
											<img src={filterLastMonthActive ? imgCheckOn : imgCheckOff} alt="" onClick={() => setFilterLastMonthActive(!filterLastMonthActive)} />
										</div>
									</div>
								</div>
								<div className="filter-inner">
									<div className="filter-column">
										<div className="filter-row">
											<label>Сумма заказов в месяц более, ₽</label>
											<input type="text" placeholder="0" className="small-input" maxLength={6} {...bindFilterAmountMore} />
										</div>
									</div>
									<div className="filter-column">
										<div className="filter-row">
											<label>Сумма заказов в месяц менее, ₽</label>
											<input type="text" placeholder="0" className="small-input" maxLength={6} {...bindFilterAmountLess} />
										</div>
									</div>
								</div>
								<div className="filter-inner">
									<div className="filter-column">
										<div className="filter-row">
											<label>Количество заказов в месяце менее, шт</label>
											<input type="text" placeholder="0" className="small-input" maxLength={2} {...bindFilterCountLess} />
										</div>
									</div>
									<div className="filter-column">
										<div className="filter-row">
											<label>Количество заказов в месяце более, шт</label>
											<input type="text" placeholder="0" className="small-input" maxLength={2} {...bindFilterCountMore} />
										</div>
									</div>
								</div>
								<div className="buttons">
									<button type="button" className="btn-second" onClick={filterReset}>Сбросить</button>
									<button type="button" onClick={filterApply}>Применить</button>
								</div>
							</div> : null}
					</div>
					<div className="products">
						<div className="list">
							{loadinginner ? <LoaderInline /> :
								clientsAll.length ?
										clientsAll.map((v,i) => <div key={i} className="product">
											<div>
												{v.name||Utils.phoneFormatter(v.phone)}
												{v.name ? <span>{Utils.phoneFormatter(v.phone)}</span> : null}
											</div>
											<img src={isSelect(v.phone) ? imgCheckOn : imgCheckOff} alt="" onClick={() => clientSelect(v)} />
										</div>)
									:
										<div className="product-empty">
											<div>
												Ничего не найдено
											</div>
										</div>
								}
						</div>
					</div>
					<div className="buttons">
						<button type="button" onClick={clientAdd} className="btn-accept">Добавить</button>
					</div>
				</div>
			</ModalClients>
			<ModalClientsList>
				<div className="modal modal-clients modal-clients-lite">
					<div className="header">
						<h4>Список клиентов</h4>
						<img src={imgClose} alt="" onClick={modalClientsListClose} className="btn-close" />
					</div>
					<div className="products">
						<div className="list">
							{tranClients.map((v,i) => <div key={i} className="product">
								<div>
									{v.name||Utils.phoneFormatter(v.phone)}
									{v.name ? <span>{Utils.phoneFormatter(v.phone)}</span> : null}
								</div>
							</div>)}
						</div>
					</div>
				</div>
			</ModalClientsList>
		</>
	);
};

export default LoyalityBonusesScreen;