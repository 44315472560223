import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import SideMenu from '../../../Components/SideMenu';

// models
import {AccountCheckLists} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// constants
import { commonStatus } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgCheckOff from './Images/check-off.svg';
import imgCheckOn from './Images/check-on.svg';
import imgCheck from './Images/check.svg';


// start
const AccountCheckListScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [checkList, setCheckList] = useState(null);
	const [steps, setSteps] = useState([]);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await checkListGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const checkListGet = async () => {
		const checkList = await AccountCheckLists.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (checkList === undefined) return;
		setCheckList(checkList);
		setSteps(checkList.steps);
	};
	const done = async (i) => {
		const res = await AccountCheckLists.set(i).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
		});
		if (res) {
			successShow(res.message);
			checkListGet();
		}
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container">
							<div className="categories-container checklist-container">
							<SideMenu title="Обзор настроек" type="account" allHide={true} />
								<div className="products-list">
									<div className="container-inner">
										{steps.map((v,i) => <div key={i} className="product-view-list-check">
											{v.status === commonStatus.ACTIVE ? <img src={imgCheckOn} alt="" /> : <img src={imgCheckOff} alt="" />}
											<div>
												<b>{v.name}</b>
												{v.description ? <span>{v.description}</span> : null}
												<div className="link"><Link to={`${v.path}`}>{v.status !== commonStatus.ACTIVE ? 'изменить' : 'перейти'}</Link></div>
											</div>
											{v.status === commonStatus.ACTIVE ?
													<div className="btn btn-done">
														<img src={imgCheck} alt="" />
														выполнено
													</div>
												:	
													<div className="btn" onClick={() => done(i)}>отметить выполненым</div>
											}
										</div>)}
									</div>
								</div>
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default AccountCheckListScreen;