import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	get		:	async ()		=> await Http.request('partner'),
	code	:	async (code)	=> await Http.request(`partner/code/${code}`)
};

const domains = {
	check	:	async (data)	=> await Http.request('partner/domain', httpMethods.POST, data),
	update	:	async (data)	=> await Http.request('partner/domains', httpMethods.PUT, data)
};

const license	= async ()			=> await Http.request('partner/license');

const update	= async (id, data)	=> await Http.request(`partner/${id}`, httpMethods.PUT, data);


export {
	get,
	domains,
	license,
	update
}