import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';

// models
import {LoyalityTransactions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';


// start
const LoyalityTransactionsScreen = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState(null);
	const [transactionsAll, setTransactionsAll] = useState([]);
	const [id, setId] = useState(null);
	const [amount, setAmount] = useState('');
	const [comment, setComment] = useState('');
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('');
	const [dateTill, setDateTill] = useState('');
	const [createdAt, setCreatedAt] = useState('');
	const [search, setSearch] = useState('');
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await loyalitiesGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const loyalitiesGet = async () => {
		const transactions = await LoyalityTransactions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setTransactions(transactions);
		setTransactionsAll(transactions);
	};
	const transactionShow = (transaction) => show(transaction);
	const show = (transaction) => {
		setId(transaction?transaction._id:null);
		setAmount(transaction?transaction.amount:'');
		setComment(transaction?transaction.comment:'');
		setName(transaction?transaction.client?.name:'');
		setPhone(transaction?transaction.client?.phone:'');
		setDateTill(transaction?transaction.properties.dateTill:'');
		setCreatedAt(transaction?transaction.createdAt:'');
	};
	const cancel = () => transactionShow(null);
	const searchTransactions = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setTransactions(transactionsAll);
		else setTransactions(transactionsAll.filter(f => f.client.phone.toLowerCase().includes(search.toLowerCase()) || f.client.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	};
	const goto = (link) => history.push(link);
	const profileShow = (v) => goto(`/books/client/${v.client.id}`);
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Бонусы"
							type="marketing"
							data={transactions}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'createdAt',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Комментарий',field:'comment'},
								{title:'Клиент',field:'client',func:(v) => <>{v.client.name}<span>{Utils.phoneFormatter(v.client.phone)}</span></>},
								{title:'Сумма',field:'amount',class:'prices',func:(v) => v.amount+' ₽'},
							]}
							rowsShort={['createdAt','amount']}
							rowControlItems={[
								{title:'Профиль клиента',class:'profile',action:(v) => profileShow(v)}
							]}
							empty={<>Добавьте бонусы<br/>программы лояльности</>}
							contentShowTitle={moment(createdAt).format('DD.MM.YYYY HH:mm')}
							contentShow={<>
								{comment ? 
									<div className="product-view-row-simple">
										{comment}
									</div> : null}
								<h4>Клиент</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Имя</span>
									<div>{name}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Телефон</span>
									<div>{Utils.phoneFormatter(phone)}</div>
								</div>
								<h4>Параметры</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Бонусов</span>
									<div>{amount?`${amount} ₽`:'–'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Действует до</span>
									<div>{moment(dateTill).format('DD.MM.YYYY HH:mm')}</div>
								</div>
							</>}
							onSearch={searchTransactions}
							onShow={transactionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
		</>
	);
};

export default LoyalityTransactionsScreen;