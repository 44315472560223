import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

// components
import Alert,{errorShow, successShow} from '../../Components/Alert';

// models
import {Users} from '../../Models';

// helpers
import Utils from '../../Globals/Utils';

// styles
import './styles.css';

// images
import imgLogo from './Images/logo.svg';


// start
const ForgotScreen = () => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	useEffect(async () => { }, []);
	const next = async () => {
		if (Utils.empty(email)) {
			setEmailError(true);
			return;
		}
		const res = await Users.forgot({email:email}).catch((ex) => {
			console.log(666, ex);
			return error(ex);
		});
		if (res) {
			setEmailError(false);
			successShow('Инструкция по восстановлению пароля отправлена на указанный email');
			goto(`/new-password/${res.code}`);
		}
	};
	const handleEmail = (e) => setEmail(e.target.value);
	const error = (message) => {
		setEmailError(true);
		errorShow(message.message);
	};
	const goto = (link) => history.push(link);
	return (
		<>
			<div className="forgot-container">
				<img src={imgLogo} alt="" />
				<div className="form-column">
					<label>
						<input type="text" placeholder=" " iserror={`${emailError!==undefined&&emailError}`} value={email} onChange={handleEmail} className="input-panel" maxLength={32} />
						<span className="placeholder">Эл.почта</span>
					</label>
				</div>
				<div className="buttons">
					<button onClick={next} className="btn">Восстановить</button>
					<div className="forgot">
						<span>Есть аккаунт?</span> <Link to="/login">Войти</Link>
					</div>
				</div>
			</div>
			<Alert />
		</>
	);
}

export default ForgotScreen;