const compareType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1,
	LESS		: 2,
	MORE		: 3,
	LESS_EQUAL	: 4,
	MORE_EQUAL	: 5
};
const compareTypeName = ['равно','не равно','меньше','больше','меньше или равно','больше или равно'];

const compareLiteType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1
};
const compareLiteTypeName = ['равно','не равно'];

const concatenateType = {
	AND		: 0,
	OR		: 1
};
const concatenateTypeName = ['И','ИЛИ'];

const directionType = {
	BEFORE	: 0,
	AFTER	: 1
};
const directionTypeName = ['до','после'];

const basketDiscountType = {
	PERCENT		: 0,
	AMOUNT_EACH	: 1,
	AMOUNT_ALL	: 2
};
const basketDiscountTypeName = ['Скидка, %','сумма на каждую позицию','сумма на все позиции'];

const eventRuleType = {
	UNKNOWN					: 0,
	NEW_ORDER				: 1,
	ORDER_STATUS_CHANGE		: 2,
	PAYMENT_STATUS_CHANGE	: 3,
	ORDER_REVIEW_ADD		: 4
};
const eventRuleTypeName = ['','Оформлен заказ','Изменен статус заказа','Изменен статус платежа','Оставлен отзыв о заказе'];

const basketRules = [
	{title:'Заказ',items:[
		{title:'Количество позиций',description:'Количество позиций, удовлетворяющих',type:'product_count',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:0,
			children:[
				{title:'Позиция в корзине',type:'product_in_basket',comparelite:compareLiteType.EQUAL,value:[]},
				{title:'Стоимость позиции (цена × количество) в корзине',type:'product_price_full',compare:compareType.EQUAL,value:0},
				{title:'Цена позиции в корзине',type:'product_price',compare:compareType.EQUAL,value:0},
				{title:'Количество позиций в корзине',type:'product_count_basket',compare:compareType.EQUAL,value:0}
			]
		},
		{title:'Сумма позиций',description:'Сумма позиций, удовлетворяющих',type:'products_sum',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:0,
			children:[
				{title:'Позиция в корзине',type:'product_in_basket',comparelite:compareLiteType.EQUAL,value:[]},
				{title:'Стоимость позиции (цена × количество) в корзине',type:'product_price_full',compare:compareType.EQUAL,value:0},
				{title:'Цена позиции в корзине',type:'product_price',compare:compareType.EQUAL,value:0},
				{title:'Количество позиций в корзине',type:'product_count_basket',compare:compareType.EQUAL,value:0}
			]
		},
		{title:'Сумма всех позиций в заказе',description:'Сумма позиций в заказе',type:'order_price',compare:compareType.EQUAL,value:0},
		{title:'День недели',type:'day_of_week',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Дата и время',description:'Дата и время заказа, удовлетворяют',type:'date_time',concatenate:concatenateType.AND,value:null,
			children:[
				{title:'Дата заказа',type:'order_date',compare:compareType.EQUAL,value:null},
				{title:'Время заказа',type:'order_time',compare:compareType.EQUAL,value:null}
			]
		},
		{title:'Предзаказ',description:'Дата и время предзаказа, удовлетворяют',type:'preorder_date_time',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:null,
			children:[
				{title:'Дата предзаказа',type:'preorder_date',compare:compareType.EQUAL,value:null},
				{title:'Время предзаказа',type:'preorder_time',compare:compareType.EQUAL,value:null}
			]
		},
		{title:'Предзаказ (дни)',description:'Дата предзаказа наступит через',type:'preorder_days',postfix:'д.',compare:compareType.EQUAL,value:1},
		{title:'Предзаказ (часы)',description:'Время предзаказа наступит через',type:'preorder_hours',postfix:'ч.',compare:compareType.EQUAL,value:1},
		{title:'Предзаказ (минуты)',description:'Время предзаказа наступит через',type:'preorder_minutes',postfix:'мин.',compare:compareType.EQUAL,value:60},
		{title:'Предзаказ (дни недели)',description:'День недели предзаказа',type:'preorder_day_of_week',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Источник заказа',type:'order_source',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Доставка или самовывоз',type:'order_delivery',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Зона доставки',type:'delivery_area',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Способ оплаты',type:'payment_type',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Промокод',type:'promo_code',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Тип промокода',type:'promo_code_type',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Первый заказ (новый клиент)',type:'is_first_order',comparelite:compareLiteType.EQUAL,value:1}
	]},
	{title:'Клиент',items:[
		{title:'День рождения (да/нет)',description:'День рождения',type:'is_birthday',comparelite:compareLiteType.EQUAL,value:1},
		{title:'День рождения (дни)',description:'Количество дней',type:'birthday_days',prefix:'дня рождения',postfix:'д.',compare:compareType.EQUAL,direction:directionType.BEFORE,value:1},
		{title:'Дата рождения',type:'birthday_date',compare:compareType.EQUAL,value:null},
		{title:'Возраст',type:'age',compare:compareType.EQUAL,value:0},
		{title:'Количество баллов у клиента',type:'client_bonuses',compare:compareType.EQUAL,value:0},
		{title:'Статус клиента',type:'client_status',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Количество заказов клиента',description:'Количество заказов за последние',type:'client_orders',postfix:'шт.',compare:compareType.EQUAL,value:0},
		{title:'Сумма заказов клиента',description:'Сумма заказов за последние',type:'client_orders_sum',postfix:'₽',compare:compareType.EQUAL,value:0}
	]}
];

const basketRuleActions = [
	{title:'Заказ',items:[
		{title:'Подарок',description:'Предоставить',type:'product_free',value:1,products:[],placeholder:'Подарок',comment:'',postfix:'подарок'},
		{title:'Подарок на выбор',description:'Предоставить',type:'products_gift',value:1,products:[],placeholder:'Подарок на выбор',comment:'',postfix:'подарок на выбор'},
		{title:'Рекомендовать позиции',type:'products_recommend',products:[]},
		{title:'Обязательная позиция (платно)',description:'Добавить',type:'product_required',value:1,products:[],placeholder:'Обязательная',comment:'',postfix:'платную позицию'},
		{title:'Обязательная позиция (бесплатно)',description:'Добавить',type:'product_required_free',value:1,products:[],placeholder:'Обязательная',comment:'',postfix:'бесплатная позиция'},
		{title:'Скидка на позиции в корзине',description:'Применить скидку',type:'products_discount',placeholder:'Скидка на позиции в корзине',comment:'',value:0,postfix:'на позиции в корзине'},
		{title:'Фиксированная цена на позиции',description:'Установить фиксированную цену',type:'products_discount_price',value:0,products:[],postfix:'₽ на набор из позиций'},
		{title:'Самая дешевая позиция бесплатно',description:'Сделать бесплатными',type:'products_cheapest_free',value:1,placeholder:'Бесплатно',comment:'',postfix:'самую дешевую позицию'},
		{title:'Скидка на самую дешевую позицию в корзине',description:'Применить скидку',type:'products_cheapest_discount',value:0,products:[],placeholder:'Скидка на самую дешевую позицию',comment:'',postfix:'для самой дешевой позиции'},
		{title:'Скидка на самую дорогую позицию в корзине',description:'Применить скидку',type:'products_expensive_discount',value:0,products:[],placeholder:'Скидка на самую дорогую позицию',comment:'',postfix:'для самой дорогой позиции'}
	]},
	{title:'Ограничения',items:[
		{title:'Отключить промокоды',type:'promo_code_disable',value:1,placeholder:'Не выполнены условия акции',comment:''},
		{title:'Отключить скидки',type:'discounts_disable',value:1,placeholder:'Не выполнены условия акции',comment:''},
		{title:'Отключить подарки на выбор',type:'gifts_disable',value:1},
		{title:'Отключить подарки от суммы',type:'gifts_sum_disable',value:1},
		{title:'Отключить бесплатную доставку',type:'free_delivery_disable',value:1},
		{title:'Отключить оплату бонусами',type:'payment_bonus_disable',value:1},
		{title:'Отключить программу лояльности',type:'loyality_disable',value:1}
	]}
];

const eventRules = [
	{title:'Заказ',items:[
		{title:'Новый статус платежа',type:'payment_status_new',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Старый статус платежа',type:'payment_status_old',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Количество позиций',description:'Количество позиций, удовлетворяющих',type:'product_count',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:0,
			children:[
				{title:'Позиция в корзине',type:'product_in_basket',comparelite:compareLiteType.EQUAL,value:[]},
				{title:'Стоимость позиции (цена × количество) в корзине',type:'product_price_full',compare:compareType.EQUAL,value:0},
				{title:'Цена позиции в корзине',type:'product_price',compare:compareType.EQUAL,value:0},
				{title:'Количество позиций в корзине',type:'product_count_basket',compare:compareType.EQUAL,value:0}
			]
		},
		{title:'Сумма позиций',description:'Сумма позиций, удовлетворяющих',type:'products_sum',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:0,
			children:[
				{title:'Позиция в корзине',type:'product_in_basket',comparelite:compareLiteType.EQUAL,value:[]},
				{title:'Стоимость позиции (цена × количество) в корзине',type:'product_price_full',compare:compareType.EQUAL,value:0},
				{title:'Цена позиции в корзине',type:'product_price',compare:compareType.EQUAL,value:0},
				{title:'Количество позиций в корзине',type:'product_count_basket',compare:compareType.EQUAL,value:0}
			]
		},
		{title:'Сумма всех позиций в заказе',description:'Сумма позиций в заказе',type:'order_price',compare:compareType.EQUAL,value:0},
		{title:'День недели',type:'day_of_week',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Дата и время',description:'Дата и время заказа, удовлетворяют',type:'date_time',concatenate:concatenateType.AND,value:null,
			children:[
				{title:'Дата заказа',type:'order_date',compare:compareType.EQUAL,value:null},
				{title:'Время заказа',type:'order_time',compare:compareType.EQUAL,value:null}
			]
		},
		{title:'Предзаказ',description:'Дата и время предзаказа, удовлетворяют',type:'preorder_date_time',concatenate:concatenateType.AND,compare:compareType.EQUAL,value:null,
			children:[
				{title:'Дата предзаказа',type:'preorder_date',compare:compareType.EQUAL,value:null},
				{title:'Время предзаказа',type:'preorder_time',compare:compareType.EQUAL,value:null}
			]
		},
		{title:'Предзаказ (дни)',description:'Дата предзаказа наступит через',type:'preorder_days',postfix:'д.',compare:compareType.EQUAL,value:1},
		{title:'Предзаказ (часы)',description:'Время предзаказа наступит через',type:'preorder_hours',postfix:'ч.',compare:compareType.EQUAL,value:1},
		{title:'Предзаказ (минуты)',description:'Время предзаказа наступит через',type:'preorder_minutes',postfix:'мин.',compare:compareType.EQUAL,value:60},
		{title:'Предзаказ (дни недели)',description:'День недели предзаказа',type:'preorder_day_of_week',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Источник заказа',type:'order_source',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Доставка или самовывоз',type:'order_delivery',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Зона доставки',type:'delivery_area',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Способ оплаты',type:'payment_type',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Промокод',type:'promo_code',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Тип промокода',type:'promo_code_type',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Первый заказ (новый клиент)',type:'is_first_order',comparelite:compareLiteType.EQUAL,value:1}
	]},
	{title:'Клиент',items:[
		{title:'День рождения (да/нет)',description:'День рождения',type:'is_birthday',comparelite:compareLiteType.EQUAL,value:1},
		{title:'День рождения (дни)',description:'Количество дней',type:'birthday_days',prefix:'дня рождения',postfix:'д.',compare:compareType.EQUAL,direction:directionType.BEFORE,value:1},
		{title:'Дата рождения',type:'birthday_date',compare:compareType.EQUAL,value:null},
		{title:'Возраст',type:'age',compare:compareType.EQUAL,value:0},
		{title:'Количество баллов у клиента',type:'client_bonuses',compare:compareType.EQUAL,value:0},
		{title:'Статус клиента',type:'client_status',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Количество заказов клиента',description:'Количество заказов за последние',type:'client_orders',postfix:'шт.',compare:compareType.EQUAL,value:0},
		{title:'Сумма заказов клиента',description:'Сумма заказов за последние',type:'client_orders_sum',postfix:'₽',compare:compareType.EQUAL,value:0}
	]}
];

const eventRuleActions = [
	{title:'Общие действия',items:[
		{title:'Добавить метку к заказу',type:'order_label_add',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Добавить метку клиенту',type:'client_label_add',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Удалить метку у клиента',type:'client_label_remove',comparelite:compareLiteType.EQUAL,value:[]},
		{title:'Начислить бонусные баллы клиенту',description:'Начислить',type:'client_bonuses_add',value:0,placeholder:'Начисление баллов',comment:'',postfix:'бонусных баллов'},
		{title:'Списать бонусные баллы у клиента',description:'Списать',type:'client_bonuses_remove',value:0,placeholder:'Списание баллов',comment:'',postfix:'бонусных баллов'}
	]},
	{title:'Сообщения',items:[
		{title:'Отправить СМС',type:'sms_send',value:1},
		{title:'Отправить СМС (клиенту)',type:'sms_send_client',value:1},
		{title:'Отправить Push',type:'push_send',value:1},
		{title:'Отправить сообщение в Telegram',type:'telegram_send',value:1}
	]}
];


export {
	eventRules,
	eventRuleActions,
	compareType,
	compareTypeName,
	compareLiteType,
	compareLiteTypeName,
	concatenateType,
	concatenateTypeName,
	directionType,
	directionTypeName,
	basketRules,
	basketRuleActions,
	basketDiscountType,
	basketDiscountTypeName,
	eventRuleType,
	eventRuleTypeName
};