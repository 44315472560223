import { API } from '../../Globals/Constants';


const dataGet = async (type) => {
	const url = `${API.core}/version/${type}`;
	const response = await fetch(url);
	const text = await response.text();
	return text;
};

const get	= async (type)	=> await dataGet(type);


export {
	get
};