import Http from '../../Globals/Http';


const get		= async (pageId)	=> await Http.request(`loyalitytransactions/${pageId||0}`);

const add		= async (clientId)	=> await Http.request(`loyalitytransactions/client/${clientId}`);


export {
	get,
	add
};