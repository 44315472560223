import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get = {
	all			: async () => await Http.request('tariffs'),
	paid		: async () => await Http.request('tariffs/paid')
};

const pay		= async (data)		=> await Http.request('tariff/payment', httpMethods.POST, data);

const bill		= async (data)		=> await Http.request('tariff/payment/bill', httpMethods.POST, data);


export {
	get,
	pay,
	bill
};