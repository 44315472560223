import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Alert,{successShow} from '../../../Components/Alert';
import Setting,{settingSave} from '../../../Components/Setting';
import SideMenu from '../../../Components/SideMenu';

// models
import {AccountCheckLists, Settings} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { settingTarget, settingTargetName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgLoader from './Images/loader.svg';


// start
const PointSettingsScreen = () => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [pointId, setPointId] = useState(null);
	const [settings, setSettings] = useState([]);
	const [selectTarget, setSelectTarget] = useState(null);
	const [isBlock, setIsBlock] = useState(false);
	const [isError, setIsError] = useState(false);
	const skip = [settingTarget.MOBILE, settingTarget.INTEGRATIONS, settingTarget.MESSAGES, settingTarget.DESIGN];
	let settingStorage = [];
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const point = ls('point');
			setPartnerId(point.partnerId);
			setPointId(point.id);
			await settingsGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const settingsGet = async () => {
		const settings = await Settings.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (settings === undefined) return;
		setSettings(settings);
	};
	const sectionSelect = (target, id) => {
		const el = document.querySelector(`#${id}`);
		if (el) {
			const s = document.getElementsByClassName('section');
			for (let i = 0; i < s.length; i++) s[i].style = null;
			el.scrollIntoView({behavior:'smooth'});
			el.style.background = '#fcfccc';
			setTimeout(() => {
				el.style.background = null;
				el.style.transition = 'background 1.5s ease-in-out';
			}, 1000);
		}
		if (target !== null) setSelectTarget(target === selectTarget ? null : target);
	};
	const settingChange = (setting) => {
		if (settingStorage.find(f => f.code === setting.code) === undefined) {
			if (!Utils.empty(setting.value))
				settingStorage.push(setting);
		}
		else {
			const ss = settingStorage.find(f => f.code === setting.code);
			if (ss) {
				const s = settings.find(f => f.code === setting.code);
				if (Utils.empty(s.value) && Utils.empty(setting.value)) {
					const ss2 = settingStorage.filter(f => f.code !== setting.code);
					settingStorage = ss2;
				} else {
					settingStorage.forEach((v,i) => {
						if (v.code === setting.code) settingStorage[i] = setting;
					});
				}
			}
		}
	};
	const save = async () => {
		setIsBlock(true);
		for (const ss of settingStorage) {
			await settingSave({...ss,partnerId,pointId}).catch((ex) => {
				console.log(666, ex);
			});
		}
		await AccountCheckLists.set(4).catch((ex) => {
			console.log(666, ex);
		});
		setIsBlock(false);
		successShow('Настройки успешно сохранены');
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<div className="components-container point-settings-container">
							<div className="categories-container">
							<SideMenu title="Настройка филиала" type="account" allHide={true} />
								<div className="products-list products-list-settings">
									<div className="container-inner settings-container-inner">
										{settingTargetName.map((v,i) => skip.includes(i) ? null : <div key={i} className="settings-content-block">
											<div key={i} id={`s${i}`} className="settings-content-title">{v}</div>
											{settings.filter(f => f.target === i).map((v,j) => <div key={j+1000} className="section" id={`s${(i+1)}s${(j+1)}`}>
												<Setting setting={{...v,partnerId}} isEditShow={true} isSaveExt={true} onChange={(s) => settingChange(s)} />
											</div>)}
										</div>)}
									</div>
									<div className="settings-container-footer">
										{isBlock ? <div className="btn-save btn-wait"><img src={imgLoader} alt="" />Сохранение</div> : <button type="button" onClick={save} className="btn-save">Сохранить</button>}
									</div>
								</div>
							</div>
							<div className="product-edit">
								<div className="settings-sections">
									{settingTargetName.map((v,i) => skip.includes(i) ? null : <React.Fragment key={i}>
										<div className="settings-sections-item" onClick={() => sectionSelect(i, `s${i}`)}>
											<div className="product-edit-list-title">{v}</div>
											{i === settingTarget.COMMON || i === settingTarget.SITE ?
													<div className={`arrow${selectTarget===i?' active':''}`}></div>
												: null}
										</div>
										{selectTarget === i ?
												(i === settingTarget.COMMON || i === settingTarget.SITE ?
														settings.filter(f => f.target === i).map((v,j) => <div key={j+1000} className="settings-sections-item-inner" onClick={() => sectionSelect(null, `s${(i+1)}s${(j+1)}`)}>
															{v.name}
														</div>)
													: null)
											: null}
									</React.Fragment>)}
								</div>
							</div>
						</div>
					)
				}
			</Template>
			<Alert />
		</>
	);
};

export default PointSettingsScreen;