import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const login		= async (username, password)	=> await Http.request('user/login', httpMethods.POST, {username,password});

const get		= async ()						=> await Http.request('users');
const getById	= async (id)					=> await Http.request(`user/${id}`);
const getByCode	= async (code)					=> await Http.request(`user/code/${code}`);

const getSalary	= async (id)					=> await Http.request('users/salary');

const profile	= async ()						=> await Http.request('user/profile');

const add		= async (data)					=> await Http.request('user', httpMethods.POST, data);
const update	= async (id, data)				=> await Http.request(`user/${id}`, httpMethods.PUT, data);

const forgot	= async (data)					=> await Http.request('user/forgot', httpMethods.POST, data);
const reset		= async (data)					=> await Http.request('user/reset', httpMethods.PATCH, data);

const status	= async (id, data)				=> await Http.request(`user/status/${id}`, httpMethods.PATCH, data);
const worktime	= async (id, data)				=> await Http.request(`user/worktime/${id}`, httpMethods.PATCH, data);
const salary	= async (id, data)				=> await Http.request(`user/salary/${id}`, httpMethods.PATCH, data);
const formula	= async (id, data)				=> await Http.request(`user/salary/formula/${id}`, httpMethods.PATCH, data);

const imageAdd	= async (id, data)				=> await Http.request(`user/image/${id}`, httpMethods.POST, data);


export {
	login,
	get,
	getById,
	getByCode,
	getSalary,
	profile,
	add,
	update,
	forgot,
	reset,
	status,
	worktime,
	salary,
	formula,
	imageAdd
}