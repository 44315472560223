import React, {useEffect,useState} from 'react';

// components
import Empty from '../Empty';
import {useInput} from '../Hooks/Input';
import Alert,{errorShow,successShow} from '../Alert';

// models
import {Settings} from '../../Models';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { API, settingTarget, settingTargetCodesName, settingType, timezones } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgEdit from './Images/edit.svg';
import imgNoImage from './Images/no-image.svg';

// methods
let settingSave = null;


// start
const Setting = (props) => {
	const [id, setId] = useState(null);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const {value:value, setValue:setValue, bind:bindValue} = useInput('');
	const [prefix, setPrefix] = useState('');
	const [postfix, setPostfix] = useState('');
	const [type, setType] = useState('');
	const [target, setTarget] = useState(settingTarget.COMMON);
	const [targetUrl, setTargetUrl] = useState(settingTarget.COMMON);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isSaveExt, setIsSaveExt] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			if (props.isSaveExt) props.setting.targetUrl = settingTargetCodesName[props.setting.target];
			settingShow(props.setting)
			setIsShow(props.isShow);
			setIsEditShow(props.isEditShow);
			setIsSaveExt(props.isSaveExt);
		};
		await dataGet();
	}, [props]);
	useEffect(async () => {
		if (props.onChange) {
			props.onChange({...props.setting, value:value});
		}
	}, [value]);
	const settingEdit = () => props.onEdit(props.setting);
	const settingShow = (setting) => show(setting);
	const show = (setting) => {
		const partnerId = setting?.partnerId;
		const targetUrl = setting?.targetUrl;
		setId(setting?._id||null);
		setName(setting?.name||'');
		setDescription(setting?.description||'');
		setValue(setting ? (setting.type === settingType.FORM ? JSON.parse(setting.value||'{}') : setting.value) : '');
		if (setting) {
			if (setting.type === settingType.FORM) setValue(JSON.parse(setting.value||'{}'));
			if (setting.type === settingType.IMAGE) setValue(imageUrlGet(partnerId, targetUrl, setting._id, setting.value));
			if (setting.type === settingType.GALLERY) setValue(setting.value && setting.value.length ? setting.value.map((v) => imageUrlGet(partnerId, targetUrl, setting._id, v)) : null);
		} else setValue('');
		setPrefix(setting?setting.prefix:'');
		setPostfix(setting?setting.postfix:'');
		setType(setting?setting.type:'');
		setTarget(setting?setting.target:'');
		setTargetUrl(targetUrl);
	};
	const cancel = () => {
		setIsEditShow(false);
		setIsShow(false);
	};
	const dataPrepare = (value) => {
		const fromdata = document.getElementById('formTemplate');
		const inputs = fromdata.getElementsByTagName('input');
		value?.keys?.forEach((v) => {
			if (v.type === 'checkbox') v.value = inputs[v.id].checked;
			if (v.type === 'input' || v.type === 'password') v.value = inputs[v.id].value;
		});
		return JSON.stringify(value);
	};
	const save = async (e) => {
		e.preventDefault();
		await setSave(id, type, value, targetUrl, true);
	};
	const setSave = async (id, type, value, targetUrl, isInner) => {
		const data = {
			value:type === settingType.FORM ? dataPrepare(value) : value,
		};
		if (isInner) {
			if (Utils.empty(data.value)) {
				errorShow('Необходимо заполнить значение');
				return;
			}
		}
		if (type === settingType.IMAGE) {
			if (Utils.isImage(value)) data.value = `${id}.${Utils.imageExtGet(value)}`;
			else {
				if (props.onUpdate) props.onUpdate();
				if (isInner) cancel();
				return;	
			}
		}
		let gallery = null;
		if (type === settingType.GALLERY) {
			gallery = [...value||[]];
			data.value = (value||[]).map((v,i) => {
				const data = imagePrepare(v);
				return data ? `${id}-${i}.${Utils.imageExtGet(v)}` : value;
			});
		}
		const res = await Settings.update(id, data).catch((ex) => {
			console.log(666, ex);
			if (isInner) errorShow();
		});
		if (res) {
			if (type === settingType.IMAGE) await imageUpload(id, targetUrl, value);
			if (type === settingType.GALLERY) await imagesUpload(id, targetUrl, gallery);
			if (props.onUpdate) props.onUpdate();
			if (isInner) {
				successShow(res.message);
				cancel();
			}
		}
	};
	const imageUpload = async (id, targetUrl, value) => {
		const ext = Utils.imageExtGet(value);
		if (ext) {
			const data = {
				name:`${id}.${ext}`,
				data:imagePrepare(value),
				target:targetUrl
			};
			await Settings.imageAdd(id, data).catch((ex) => console.log(666, ex));
		}
	};
	const imagesUpload = async (id, targetUrl, value) => {
		value.forEach(async (v, i) => {
			const ext = Utils.imageExtGet(v);
			if (ext) {
				const data = {
					name:`${id}-${i}.${ext}`,
					data:imagePrepare(v),
					target:targetUrl
				};
				await Settings.imageAdd(id, data).catch((ex) => console.log(666, ex));
			}
		});
	};
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const imageError = (e) => {
		e.target.src = imgNoImage;
		e.target.parentNode.className += ' product-edit-image-error';
		e.target.className = 'image image-error';
	};
	const imageDelete = () => setValue(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setValue(r.target.result);
		reader.readAsDataURL(file);
	};
	const handleGallery = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setValue([...value||[], r.target.result]);
		reader.readAsDataURL(file);
	};
	const imageUrlGet = (partnerId, targetUrl, id, value) => `${API.assets}partners/${partnerId}/media/${targetUrl}/${id}/${value}?v=${new Date().getTime()}`;
	const controlRender = (type, value, isfocus) => {
		const n = Utils.code(4);
		switch (type) {
			case settingType.INPUT:
				return <input type="text" {...bindValue} placeholder="Значение" autoFocus={isfocus} required />;
			case settingType.TEXTAREA:
				return <textarea {...bindValue} placeholder="Значение" className="large" autoFocus={isfocus} required />;
			case settingType.IMAGE:
				return <div className="product-edit-row">
					{value ?
							<div className="product-edit-images">
								<div className="product-edit-image product-edit-image-wide">
									<img src={value} alt="" onError={imageError} className="image" />
									<div className="delete" onClick={imageDelete}></div>
								</div>
							</div>
						: null}
					<label>
						<div className="product-edit-btn">
							Добавить изображение
							<input type="file" accept="image/jpeg,image/png,image/webp,image/vnd.microsoft.icon,image/x-icon,image/svg+xml" onChange={handleImage} />
						</div>
					</label>
				</div>;
			case settingType.GALLERY:
				return <div className="product-edit-row">
					{value && value.length ?
							<div className="product-edit-images">
								{value.map((v,i) => <div key={i} className="product-edit-image product-edit-image-wide">
									<img src={v} alt="" onError={imageError} />
									<div className="delete" onClick={() => setValue(value.filter(f => f !== v))}></div>
								</div>)}
							</div>
							:
								<div className="product-edit-images">
									<div className="product-edit-image product-edit-image-wide">
										<img src={imgNoImage} alt="" className="image" />
									</div>
								</div>
					}
					<label>
						<div className="product-edit-btn">
							Добавить изображение
							<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleGallery} />
						</div>
					</label>
				</div>;
			case settingType.TIMEZONE:
				return <div className="product-edit-row product-edit-row-oneline">
				<div className="select select-wide">
					<select onChange={handleValue} value={value} required>
						<option value=""></option>
						{timezones.map((v,i) => <option key={i} value={v.value}>{v.name} ({v.value})</option>)}
					</select>
				</div>
			</div>;
			case settingType.FORM:
				const out = [];
				value?.keys?.forEach((v, i) => {
					if (v.type === 'checkbox') {
						out.push(<div key={i} className="form-row form-oneline">
							<input type="checkbox" id={v.id} checked={v.value==='checked'?v.value:(v.default?'checked':null)} onChange={handleInputValue} />
							<label htmlFor={v.id}>{v.name}</label>
						</div>);
					}
					if (v.type === 'input' || v.type === 'password') {
						out.push(<div key={i} className="form-row">
							<label htmlFor={v.id}>{v.name}</label>
							<input type={v.type === 'password' ? 'password' : 'text'} id={v.id} placeholder={v.name} value={v.value} onChange={handleInputValue} />
						</div>);
					}
				});
				value?.links?.forEach((v, i) => {
					out.push(<div key={i} className="form-row">
						<a href={v.link} target="_blank">{v.title}</a>
					</div>);
				});
				return <div id="formTemplate" className="form-template">
					{out}
				</div>;
			case settingType.COLOR:
				return <input type="color" {...bindValue} placeholder="Значение" autoFocus={isfocus} required />;
			case settingType.SELECT:
				const select = [];
				let defkey = null;
				value?.split(';').forEach((v,i) => {
					const [key, val, def] = v.split(':');
					select.push({value:key,name:val});
					if (def === 'true') defkey = key;
				});
				return <div className="product-edit-row">
					<div className="select select-wide">
						<select onChange={handleSelect} value={defkey} required>
							<option value=""></option>
							{select.map((v,i) => <option key={i} value={v.value}>{v.name}</option>)}
						</select>
					</div>
				</div>;
			case settingType.CHECKBOX:
				return <div className="product-edit-row">
					<input type="checkbox" id={`cb${n}`} checked={value==='checked'?value:null} onChange={handleCheckbox} />
					<label htmlFor={`cb${n}`}>Да</label>
				</div>;
			case settingType.CHECKBOXES:
				const checkboxes = [];
				value.split(';').forEach((v,i) => {
					const [key, val, chk, req] = v.split(':');
					checkboxes.push({id:key,name:val,value:chk,required:req});
				});
				return <>
					{checkboxes.map((v,i) => <div key={i} className="product-edit-row">
						<input type="checkbox" id={`chk${v.id}`} checked={v.value==='checked'?v.value:null} onChange={(e) => handleCheckboxes(e, i)} />
						<label htmlFor={`chk${v.id}`}>{v.name}</label>
						{v.value === 'checked' ?
							<span className="chk-req">
								<input type="checkbox" id={`req${v.id}`} checked={v.required==='checked'?v.required:null} onChange={(e) => handleCheckboxesReq(e, i)} />
								<label htmlFor={`req${v.id}`}>Обязательно</label>
							</span> : null}
					</div>)}
				</>;
			default:
				return null;
		}
	};
	const handleInputValue = (e) => {
		const val = e.target.value, id = e.target.id, type = e.target.type, ischecked = type === 'checkbox' ? e.target.checked ?? e.target.value : null;
		if (type === 'checkbox') value?.keys.map((v) => v.value = v.id === id ? (ischecked ? 'checked' : null) : v.value);
		else value?.keys.map((v) => v.value = v.id === id ? val : v.value);
		setValue({...value});
	}
	const handleValue = (e) => setValue(e.target.value);
	const handleCheckbox = (e) => setValue(e.target.checked ? 'checked' : null);
	const handleCheckboxes = (e, i) => {
		const vals = value.split(';');
		const ischecked = e.target.checked ? 'checked' : '';
		const [key, name, chk, req] = vals[i].split(':');
		vals[i] = `${key}:${name}:${ischecked}:${ischecked?req:''}`;
		setValue(vals.join(';'));
	};
	const handleCheckboxesReq = (e, i) => {
		const vals = value.split(';');
		const ischecked = e.target.checked ? 'checked' : '';
		const [key, name, chk, req] = vals[i].split(':');
		vals[i] = `${key}:${name}:${chk}:${ischecked}`;
		setValue(vals.join(';'));
	};
	const handleSelect = (e) => {
		const val = e.target.value;
		const select = [];
		value?.split(';').forEach((v,i) => {
			const [key, name, def] = v.split(':');
			select.push(`${key}:${name}:${key === val}`);
		});
		setValue(select.join(';'));
	};
	const valueGet = (type, value, prefix, postfix) => {
		switch (type) {
			case settingType.INPUT:
				return <div dangerouslySetInnerHTML={{__html:`${prefix||''} ${Utils.linkFromText(value||'–')} ${postfix||''}`.trim()}} />
			case settingType.TEXTAREA:
				return `${prefix||''} ${value||'–'} ${postfix||''}`.trim();
			case settingType.IMAGE:
				return <img src={value||imgNoImage} alt="" onError={imageError} className="image" />;
			case settingType.GALLERY:
				return value && value.length ? value.map((v,i) => <img key={i} src={v} alt="" onError={imageError} className="image" />) : <img src={imgNoImage} alt="" className="image" />;
			case settingType.TIMEZONE:
				return timezones.find(f => f.value === parseInt(value))?.name;
			case settingType.FORM:
				const out = [];
				value?.keys?.forEach((v, i) => {
					if (v.type === 'checkbox') {
						out.push(<div key={i} className="product-view-row product-view-row-oneline">
							<span>{v.name}</span>
							<div>{v.value ? 'Да' : 'Нет'}</div>
						</div>);
					}
					if (v.type === 'input' || v.type === 'password') {
						out.push(<div key={i} className="product-view-row product-view-row-oneline">
							<span>{v.name}</span>
							<div>{v.value ? (v.type === 'password' ? '••••••••' : v.value) : '–'}</div>
						</div>);
					}
				});
				return out;
			case settingType.COLOR:
				return <>
					<div className="color" style={{backgroundColor:value}}></div>
					<div>{value}</div>
				</>;
			case settingType.SELECT:
				let selectvalue = null;
				value?.split(';').forEach((v,i) => {
					const [val, name, def] = v.split(':');
					if (def === 'true') selectvalue = name;
				});
				return `${selectvalue} ${postfix||''}`.trim();
			case settingType.CHECKBOX:
				return value ? 'Да' : 'Нет';
			case settingType.CHECKBOXES:
				const checkboxes = [];
				value.split(';').forEach((v,i) => {
					const [key, name, chk, req] = v.split(':');
					if (chk === 'checked') checkboxes.push({name:name,checked:true,required:req==='checked'});
				});
				return checkboxes.length === 0 ? '–' :
					checkboxes.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
						<span>
							{v.name}
							{v.required ? ' (обязательно)' : ''}
						</span>
					</div>);
			default:
				return null;
		}
	};
	settingSave = async (settings) => await setSave(settings._id, settings.type, settings.value, settings.targetUrl, false);
	return (
		<>
			<div className="product-edit">
				{isShow ?
						<div className="product-edit-list-container">
							<div className="container-inner">
								<div className="list">
									<div className="product-view-row product-view-row-simple product-view-row-oneline product-view-row-oneline-up">
										<div className="product-view-row-oneline product-view-row-oneline-start">
											<h3>{name}</h3>
										</div>
										<img src={imgEdit} alt="" className="edit" onClick={settingEdit} />
									</div>
									{description ?
										<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
									{type === settingType.FORM ?
											<>
												<h4>Параметры</h4>
												{valueGet(type, value)}
											</>
										:
											<>
												<h4>Значение</h4>
												<div className="product-view-row-simple">
													{valueGet(type, value, prefix, postfix)}
												</div>
											</>
									}
								</div>
							</div>
							<div className="product-edit-footer">
								<button type="button" onClick={cancel} className="btn-cancel btn-cancel-wide">Закрыть</button>
							</div>
						</div>
					: null}
				{isEditShow ?
						(isSaveExt ?
								<form className="product-edit-list-container">
									<div className="container-inner">
										<div className="list">
											<div className="product-edit-row">
												<h4>
													{name}
													{props.helpPanel}
												</h4>
											</div>
											{description ?
												<div className="product-view-row-description" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
											<div className="product-edit-row">
												{controlRender(type, value, false)}
											</div>
										</div>
									</div>
								</form>
							:
								<form className="product-edit-list-container" onSubmit={save}>
									<div className="container-inner">
										<div className="list">
											<div className="product-edit-row">
												<h3>{name}</h3>
											</div>
											{description ?
												<div className="product-view-row product-view-row-simple" dangerouslySetInnerHTML={{__html: Utils.rn2br(description)}} /> : null}
											<h4>{type === settingType.FORM ? 'Параметры' : 'Значение'}</h4>
											<div className="product-edit-row">
												{controlRender(type, value, true)}
											</div>
										</div>
									</div>
									<div className="product-edit-footer">
										<button type="button" onClick={cancel} className="btn-cancel">Отменить</button>
										<button type="submit" className="btn-save">Сохранить</button>
									</div>
								</form>
						)
					: null}
				{isShow || isEditShow ? null : <Empty image={'settings'} text={<>Для редактирования настройки,<br />выберите одину из списка</>} />}
			</div>
			<Alert />
		</>
	);
};

export default Setting;
export {
	settingSave
};