import React, {useEffect,useState} from 'react';

// components
import {useInput} from '../Hooks/Input';

// helpers
import Utils from '../../Globals/Utils';

// styles
import './styles.css';


const WorkTime = (props) => {
	const [workTime, setWorkTime] = useState(props.workTime||[]);
	const {value:workTimeDescription, setValue:setWorkTimeDescription, bind:bindWorkTimeDescription} = useInput('');
	const [descriptionHide, setDescriptionHide] = useState(props.descriptionHide||false);
	const [isShowAll, setIsShowAll] = useState(false);
	useEffect(() => {
		setWorkTimeDescription(props.workTimeDescription||'');
	}, []);
	useEffect(() => {
		props.onChange(workTime, workTimeDescription);
	}, [workTime]);
	const wtSet = (e, i, key) => {
		const wt = [...workTime||[]];
		wt[i][key] = e.target.value;
		setWorkTime(wt);
		wtDescSet(wt);
	};
	const isWorkTimeEmpty = () => {
		let res = false;
		workTime.forEach((v) => {
			if (Utils.empty(v.start) || Utils.empty(v.end)) res = true;
		});
		return res;
	};
	const copy = (isDescSet = true) => {
		const wt = [...workTime||[]];
		if (Utils.empty(wt[0].start) || Utils.empty(wt[0].end)) return;
		for (let i = 1; i < 7; i++) {
			wt[i].start = wt[0].start;
			wt[i].end = wt[0].end;
		}
		setWorkTime(wt);
		if (isDescSet) wtDescSet(wt);
	};
	const wtDescSet = (workTime) => {
		const wt = [...workTime||[]];
		let text = '';
		let isEveryday = true;
		wt.forEach((v) => {
			if (v.start !== workTime[0].start || v.end !== workTime[0].end) isEveryday = false;
		});
		if (isEveryday) {
			text = 'Ежедневно с %s до %s'.replace('%s', workTime[0].start).replace('%s', workTime[0].end);
			copy(false);
		}
		else {
			let isWorkday = true;
			wt.forEach((v, i) => {
				if (i !== 5 && i !== 6) {
					if (v.start !== workTime[0].start || v.end !== workTime[0].end) isWorkday = false;
				}
			});
			if (isWorkday) {
				text = 'Пн-Пт с %s до %s'.replace('%s', workTime[0].start).replace('%s', workTime[0].end);
				if (wt[5].start === wt[6].start && wt[5].end === wt[6].end) text += ', Сб-Вс с %s до %s'.replace('%s', wt[5].start).replace('%s', wt[5].end);
				else {
					if (wt[5].start && wt[5].end) text += ', Сб с %s до %s'.replace('%s', wt[5].start).replace('%s', wt[5].end);
					if (wt[6].start && wt[6].end) text += ', Вс с %s до %s'.replace('%s', wt[6].start).replace('%s', wt[6].end);
				}
			}
			else {
				if (wt[0].start && wt[0].end) text += 'Пн ' + wt[0].start + '-' + wt[0].end + ', ';
				if (wt[1].start && wt[1].end) text += 'Вт ' + wt[1].start + '-' + wt[1].end + ', ';
				if (wt[2].start && wt[2].end) text += 'Ср ' + wt[2].start + '-' + wt[2].end + ', ';
				if (wt[3].start && wt[3].end) text += 'Чт ' + wt[3].start + '-' + wt[3].end + ', ';
				if (wt[4].start && wt[4].end) text += 'Пт ' + wt[4].start + '-' + wt[4].end + ', ';
				if (wt[5].start && wt[5].end) text += 'Сб ' + wt[5].start + '-' + wt[5].end + ', ';
				if (wt[6].start && wt[6].end) text += 'Вс ' + wt[6].start + '-' + wt[6].end + ', ';
				if (!Utils.empty(text)) text = `Согласно графику: ${text}`;
			}
		}
		text = text.replace(/0(\d):/g, '$1:');
		text = text.trim().replace(/,$/, '')
		setWorkTimeDescription(text);
	};
	const allShow = () => {
		if (isWorkTimeEmpty()) copy();
		setIsShowAll(true);
	};
	const allShowTitle = () => {
		const wt = [...workTime||[]];
		return !Utils.empty(wt[0].start) && !Utils.empty(wt[0].end) ? 'Копировать на неделю' : 'Полное рассписание';
	}
	return <>
		<div className="product-edit-row">
			<div className="work-time">
				{isShowAll ?
						Utils.weekDayName.full.map((v,i) => <div key={i} className="product-edit-row product-edit-row-oneline">
							<div>{v}</div>
							<div className="product-edit-row-oneline">
								<input type="time" className="time" value={workTime[i]?.start||''} onChange={(e) => wtSet(e, i, 'start')} />
								<input type="time" className="time" value={workTime[i]?.end||''} onChange={(e) => wtSet(e, i, 'end')} />
							</div>
						</div>)
					:
						<div className="product-edit-row product-edit-row-oneline">
							<div>
								Ежедневно
								<div className="copy-day" onClick={allShow}>{allShowTitle()}</div>
							</div>
							<div className="product-edit-row-oneline">
								<input type="time" className="time" onChange={(e) => wtSet(e, 0, 'start')} />
								<input type="time" className="time" onChange={(e) => wtSet(e, 0, 'end')} />
							</div>
						</div>
				}
			</div>
		</div>
		{descriptionHide ? null :
			<div className="product-edit-row">
				<input type="text" {...bindWorkTimeDescription} placeholder="Время работы кратко" />
			</div>
		}
	</>;
};

export default WorkTime;