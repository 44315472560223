import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// helpers
import {ls} from '../../Globals/Localstorage';

// contstants
import { API, commonStatus } from '../../Globals/Constants';
import Sidemenus from '../../Globals/Sidemenus';

// images
import imgHelp from './Images/help.svg';

// styles
import './styles.css';


const SideMenu = ({title,type,children,tabs,disabled,allHide}) => {
	const [loading, setLoading] = useState(true);
	const [point, setPoint] = useState(null);
	const [user, setUser] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isAll, setIsAll] = useState(false);
	useEffect(() => {
		const user = ls('user');
		setUser(user);
		const point = ls('point');
		setPoint(point);
		const pointall = ls('pointall');
		setIsAll(pointall);
		setLoading(false);
	}, []);
	const isselect = (page) => {
		const link = window.location.pathname.split('/');
		if (link.length === 4) {
			link.pop();
			if (page.indexOf('worktime') === -1)
				page = page.substring(0, page.length - 1);
		}
		return link.join('/') === page;
	};
	const linkGet = () => {
		const link = window.location.pathname.split('/');
		link.shift();
		if (link.length === 3) link.pop();
		return link.join('/');
	};
	const showMenu = () => setIsShow(!isShow);
	const pointSwitch = (point) => {
		const p = user.points.find(f => f.id === point.id);
		ls('point', p);
		ls('pointall', false);
		window.location.reload();
	};
	const pointSwitchAll = () => {
		ls('pointall', true);
		window.location.reload();
	};
	const pointsGet = () => {
		const points = user.points.filter(f => f.status === commonStatus.ACTIVE);
		points.sort((a,b) => a.id === point.id ? -1 : 1);
		return points;
	};
	return loading ? null :
		<>
			{isShow ? <div className="categories-list-switcher-overlay" onClick={() => setIsShow(false)}></div> : null}
			<div className="components-categories-list">
				<div className="components-categories-list-title">
					<h2>{title}</h2>
					<a href={`${API.help}${linkGet()}`} target='_blank'><img src={imgHelp} alt="" /></a>
				</div>
				<div className={`categories-list-switcher${disabled?' categories-list-switcher-disabled':''}`} onClick={() => disabled ? null : showMenu()}>
					<div className={`categories-list-switcher-menu${isShow?' categories-list-switcher-menu-open':''}`}>
						{isShow ?
								<>
									{allHide ? null :
										<div onClick={() => pointSwitchAll()} className={`categories-list-switcher-menu-item${isAll?' categories-list-switcher-menu-item-select':''}`}>
											<b>Все филиалы</b>
										</div>
									}
									{pointsGet().map((v,i) => <div key={i} onClick={() => pointSwitch(v)} className={`categories-list-switcher-menu-item${isAll?'':(v.id===point.id?' categories-list-switcher-menu-item-select':'')}`}>
											<b>{v.name}</b>
										</div>)}
									<Link to={'/account/point/add'} className="categories-list-switcher-menu-item categories-list-switcher-menu-item-add">
										Добавить филиал
									</Link>
								</>
							:
								<div className="categories-list-switcher-menu-item categories-list-switcher-menu-item-single">
									{allHide ? point?.name : (isAll ? 'Все филиалы' : point?.name)}
								</div>
						}
					</div>
				</div>
				{children ?
						children
					:
						<div className="categories-list-container">
							{tabs}
							<div className="container-inner">
								<div className="list">
									{Sidemenus[type].map((v,i) => <div key={i}>
										<Link to={v.link} className={`category${isselect(v.link)?' category-select':''}`}>{v.name}</Link>
									</div>)}
								</div>
							</div>
						</div>
				}
			</div>
		</>;
};

export default SideMenu;