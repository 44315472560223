import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import InputMask from 'react-input-mask';
import {YMaps,Map,ZoomControl,Polygon} from 'react-yandex-maps';

// components
import Loader from '../../Components/Loader';
import Setting,{settingSave} from '../../Components/Setting';
import {useInput} from '../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../Components/Alert';
import Error from '../../Components/Error';
import WorkTime from '../../Components/WorkTime';

// models
import {AccountCheckLists, Addresses, Banks, Categories, Cities, DeliveryAreas, HelpDesks, Partners, Points, Products, Settings, Tariffs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import Utils from '../../Globals/Utils';
import Orfo from '../../Globals/Orfo';

// constants
import { API, commonStatus, deliveryType, MAP, measureType, measureTypeSmallName, sourceType } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import imgLogo from './Images/logo.svg';
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgCheckGreenOn from './Images/check-on.svg';
import imgCheckGreenOff from './Images/check-off.svg';
import imgEdit from './Images/edit.svg';
import imgEditStop from './Images/edit-stop.svg';
import imgHelp from './Images/help.svg';
import imgPlusButton from './Images/plus-btn.svg';
import imgNoPhoto from './Images/no-photo.svg';

// vars
const steps = [
	{title:'Данные компании',fields:[
		{title:'Данные компании',help:'с-чего-начать'},
		{title:'Контакты',help:'web-сайт'}
	]},
	{title:'Реквизиты компании',fields:[
		{title:'Реквизиты',help:''},
		{title:'Банковские реквизиты',help:''}
	]},
	{title:'Доменное имя',fields:[
		{title:'Бесплатный поддомен',help:''},
		{title:'Домен',help:''}
	]},
	{title:'Описание филиала',fields:[
		{title:'Филиал',help:''},
		{title:'Контакты',help:''},
		{title:'Область обслуживания',help:''},
		{title:'Время работы',help:''}
	]},
	{title:'Настройка филиала',fields:[
		{title:'Логотип',help:'с-чего-начать'},
		{title:'Слайдер в шапке',help:''},
		{title:'Краткое описание',help:''},
		{title:'Описание о нас',help:''},
		{title:'Подробное описание',help:''},
		{title:'Информация об оплате',help:''},
		{title:'Информация о доставке',help:''},
		{title:'Информация об акциях',help:''},
		{title:'Информация о программах лояльности',help:''},
		{title:'Текст на странице «Корзина»',help:''},
		{title:'Текст на странице «Оформление заказа»',help:''},
		{title:'Текст в подвале',help:''},
		{title:'Иконка сайта',help:''},
		{title:'Ключевые слова',help:''},
		{title:'Логотип в подвале',help:''},
		{title:'Заголовок сайта',help:''},
		{title:'Текст для копирайта',help:''},
		{title:'Цена доставки',help:''},
		{title:'Минимальная сумма заказа',help:''},
		{title:'Бесплатная доставка',help:''},
		{title:'Максимальное время доставки',help:''},
		{title:'Минимальное время доставки',help:''}
	]},
	{title:'Зоны доставки',fields:[
		{title:'Зона доставки',help:''},
		{title:'Область обслуживания',help:''},
		{title:'Время работы',help:''},
		{title:'Условия',help:''},
		{title:'Дополнительные параметры',help:''}
	]},
	{title:'Товары',fields:[
		{title:'Описание блюда',help:''},
		{title:'Категория блюда',help:''},
		{title:'Цена / Скидка',help:''},
		{title:'Фотографии',help:''},
		{title:'Объем / Вес',help:''}
	]},
	{title:'Тариф'}
];


// start
const SetupScreen = (props) => {
	const [ModalMap, openMap, closeMap] = useModal('root');
	const [ModalCategory, modalCategoryOpen, modalCategoryClose] = useModal('root');
	const [ModalDelete, modalDeleteOpen, modalDeleteClose] = useModal('root');
	const [ModalTechNameConfirm, modalTechNameConfirmOpen, modalTechNameConfirmClose] = useModal('root');
	const [ModalFinish, modalFinishOpen, modalFinishClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [step, setStep] = useState(1);
	const totalSteps = steps.length;
	const [partner, setPartner] = useState(null);
	const [point, setPoint] = useState(null);
	const [cities, setCities] = useState([]);
	const [settings, setSettings] = useState([]);
	const [deliveryAreas, setDeliveryAreas] = useState([]);
	const [productCategories, setProductCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [cityId, setCityId] = useState(null);
	const [area, setArea] = useState(null);
	const [tariffs, setTariffs] = useState([]);
	const [tariff, setTariff] = useState(null);

	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:address, setValue:setAddress, bind:bindAddress} = useInput('');

	const {value:officialName, setValue:setOfficialName, bind:bindOfficialName} = useInput('');
	const {value:officialAddress, setValue:setOfficialAddress, bind:bindOfficialAddress} = useInput('');
	const {value:inn, setValue:setInn, bind:bindInn} = useInput('');
	const {value:kpp, setValue:setKpp, bind:bindKpp} = useInput('');
	const {value:ogrn, setValue:setOgrn, bind:bindOgrn} = useInput('');
	const {value:okpo, setValue:setOkpo, bind:bindOkpo} = useInput('');
	const {value:okved, setValue:setOkved, bind:bindOkved} = useInput('');
	const [banks, setBanks] = useState([]);
	const [bank, setBank] = useState(null);
	const [bankName, setBankName] = useState(null);
	const [bankAddress, setBankAddress] = useState(null);
	const [bankBik, setBankBik] = useState(null);
	const [bankInn, setBankInn] = useState(null);
	const [bankCorrespondent, setBankCorrespondent] = useState(null);
	const {value:bankAccount, setValue:setBankAccount, bind:bindBankAccount} = useInput('');

	const {value:domain, setValue:setDomain, bind:bindDomain} = useInput('');
	const {value:subDomain, setValue:setSubDomain, bind:bindSubDomain} = useInput('');
	const [isSubDomainDisabled, setIsSubDomainDisabled] = useState(false);
	const [isDomainParked, setIsDomainParked] = useState(false);

	const [addresses, setAddresses] = useState([]);
	const [areas, setAreas] = useState([]);
	const {value:pointName, setValue:setPointName, bind:bindPointName} = useInput('');
	const {value:pointAddress, setValue:setPointAddress, bind:bindPointAddress} = useInput('');
	const {value:pointPhone, setValue:setPointPhone, bind:bindPointPhone} = useInput('');
	const {value:pointEmail, setValue:setPointEmail, bind:bindPointEmail} = useInput('');
	const [pointLatitude, setPointLatitude] = useState(null);
	const [pointLongitude, setPointLongitude] = useState(null);
	const [pointWorkTime, setPointWorkTime] = useState([]);
	const [pointWorkTimeDescription, setPointWorkTimeDescription] = useState('');
	const color = '#00ff00';

	const [deliveryAreaId, setDeliveryAreaId] = useState(null);
	const {value:deliveryAreaName, setValue:setDeliveryAreaName, bind:bindDeliveryAreaName} = useInput('');
	const {value:deliveryAreaDescription, setValue:setDeliveryAreaDescription, bind:bindDeliveryAreaDescription} = useInput('');
	const {value:deliveryAreaColor, setValue:setDeliveryAreaColor, bind:bindDeliveryAreaColor} = useInput('');
	const {value:deliveryAreaPrice, setValue:setDeliveryAreaPrice, bind:bindDeliveryAreaPrice} = useInput('');
	const {value:deliveryAreaPriceFree, setValue:setDeliveryAreaPriceFree, bind:bindDeliveryAreaPriceFree} = useInput('');
	const {value:deliveryAreaMinimumAmount, setValue:setDeliveryAreaMinimumAmount, bind:bindDeliveryAreaMinimumAmount} = useInput('');
	const {value:deliveryAreaMaxTime, setValue:setDeliveryAreaMaxTime, bind:bindDeliveryAreaMaxTime} = useInput('');
	const {value:deliveryAreaMinTime, setValue:setDeliveryAreaMinTime, bind:bindDeliveryAreaMinTime} = useInput('');
	const [deliveryAreaAlwaysPaid, setDeliveryAreaAlwaysPaid] = useState(commonStatus.ACTIVE);
	const [deliveryAreaWorkTime, setDeliveryAreaWorkTime] = useState([]);

	const [productId, setProductId] = useState(null);
	const {value:productName, setValue:setProductName, bind:bindProductName} = useInput('');
	const {value:productWeight, setValue:setProductWeight, bind:bindProductWeight} = useInput('');
	const {value:productDescription, setValue:setProductDescription, bind:bindProductDescription} = useInput('');
	const {value:productPrice, setValue:setProductPrice, bind:bindProductPrice} = useInput('');
	const {value:productPriceFull, setValue:setProductPriceFull, bind:bindProductPriceFull} = useInput('');
	const {value:productDiscount, setValue:setProductDiscount, bind:bindProductDiscount} = useInput('');
	const [productImages, setProductImages] = useState([]);
	const [productImageMainIndex, setProductImageMainIndex] = useState(null);
	const [productCategoryId, setProductCategoryId] = useState(0);
	const [productMeasureType, setProductMeasureType] = useState(measureType.GRAMS);
	const {value:categoryName, setValue:setCategoryName, bind:bindCategoryName} = useInput('');

	const [isPartnerTechName, setIsPartnerTechName] = useState(true);
	const [isPointTechName, setIsPointTechName] = useState(true);

	const [help, setHelp] = useState(null);
	const [checkSteps, setCheckSteps] = useState([]);
	const [isMapEdit, setIsMapEdit] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isBlock, setIsBlock] = useState(false);
	const [isError, setIsError] = useState(false);

	const settingsDesign = ['site-logo','site-slides','site-description-short','site-description-about','site-description-short','site-description-about','site-description-full','site-description-payment','site-description-delivery','site-description-actions','site-description-promo','site-page-cart','site-page-order','site-footer-text','site-favicon','site-keywords','site-logo-footer','site-title','site-copyright'];
	const settingsSettings = ['delivery-price','delivery-minimum','delivery-free','delivery-max-time','delivery-min-time'];

	let map = null;
	let polygons = null;
	let settingStorage = [];

	useEffect(async () => {
		await dataGet();
		await citiesGet();
		await areasGet();
		await categoriesGet();
		await productsGet();
		await tariffsGet();
	}, []);
	useEffect(() => {
		if (partner && step === 1) show(0);
	}, [partner]);
	const citiesGet = async () => {
		const cities = await Cities.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (cities === undefined) return;
		setCities(cities);
	};
	const tariffsGet = async () => {
		const tariffs = await Tariffs.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (tariffs === undefined) return;
		setTariffs(tariffs);
	};
	const settingsGet = async () => {
		const settings = await Settings.get.all().catch((ex) => {
			console.log(666, ex)
			setIsError(true);
		});
		if (settings === undefined) return;
		setSettings(settings);
	};
	const areasGet = async () => {
		const areas = await DeliveryAreas.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (areas === undefined || areas.length === 0) return;
		setDeliveryAreas(areas);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
	};
	const categoriesGet = async () => {
		const categories = await Categories.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (categories === undefined) return;
		setProductCategories(categories);
	};
	const dataGet = async () => {
		setLoading(true);
		const id = props.match.params.id;
		if (id) {
			const res = await Partners.get.code(id).catch((ex) => {
				console.log(666, ex);
			});
			if (res) {
				const user = res.user;
				const point = {
					id:res.point._id,
					name:res.point.name,
					partnerId:res.point.partnerId
				};
				user.points = [point];
				ls('user', user);
				ls('token', res.token);
				ls('point', point);
				setPartner(res);
				setPoint(point);
				await settingsGet();
				const cs = ls('checklist') || steps.slice(0,-1).map(() => false);
				setCheckSteps(cs);
				setLoading(false);
				return;
			}
		}
		clear();
	};
	const clear = () => {
		ls('user', null);
		ls('token', null);
		ls('point', null);
		ls('checklist', null);
		window.location.href = '/login';
	};
	const next = async () => {
		const partnerId = partner._id;
		const number = partner.number;
		let iserror = false;
		let data = null;
		switch (step) {
			case 1:
				if (isPartnerTechName) {
					if (name?.toLowerCase() === `partner_${number}`) {
						modalTechNameConfirmOpen();
						return;
					}
				}
				data = {
					name,
					description,
					phone,
					address
				};
				if (Utils.empty(data.name)) {
					errorShow('Необходимо заполнить название компании');
					return;
				}
				setIsBlock(true);
				await Partners.update(partnerId, data).catch((ex) => {
					console.log(666, ex);
					iserror = true;
					errorShow(ex);
				});
				break;
			case 2:
				data = {
					officialName:officialName,
					officialAddress:officialAddress,
					address:address,
					inn:inn,
					kpp:kpp,
					ogrn:ogrn,
					okpo:okpo,
					okved:okved,
					bankName:bankName,
					bik:bankBik,
					correspondent:bankCorrespondent,
					account:bankAccount
				};
				setIsBlock(true);
				await Partners.update(partnerId, data).catch((ex) => {
					console.log(666, ex);
					iserror = true;
					errorShow();
				});
				break;
			case 3:
				if (Utils.empty(domain) && Utils.empty(subDomain)) {
					errorShow('Необходимо указать домен или поддомен');
					return;
				}
				if (Utils.empty(subDomain)) {
					errorShow('Поддомен не может быть пустым. Если вы не планируете использовать поддомен, оставьте в названии техническое имя.');
					return;
				}
				data = {};
				if (domain) data.domain = domain;
				if (subDomain) {
					if (!isSubDomainDisabled) data.subDomain = subDomain;
				}
				if (Object.keys(data).length !== 0) {
					setIsBlock(true);
					await Partners.domains.update(data).catch((ex) => {
						console.log(666, ex);
						iserror = true;
						errorShow(ex.message);
					});
				}
				break;
			case 4:
				if (isPointTechName) {
					if (pointName?.toLowerCase() === `point_${number}`) {
						modalTechNameConfirmOpen();
						return;
					}
				}
				data = {
					name:pointName,
					cityId:cityId,
					address:pointAddress,
					phone:Utils.phoneNormalize(pointPhone),
					email:pointEmail,
					latitude:pointLatitude,
					longitude:pointLongitude,
					area:area,
					color:color,
					workTime:pointWorkTime,
					workTimeDescription:pointWorkTimeDescription,
					status:commonStatus.ACTIVE
				};
				if (areas.length) data.areas = areas.map(v => v.id);
				if (Utils.empty(data.name)) {
					errorShow('Необходимо заполнить название филиала');
					return;
				}
				if (Utils.empty(data.cityId)) {
					errorShow('Необходимо выбрать город филиала');
					return;
				}
				await Points.update(point.id, data)
					.catch((ex) => {
						console.log(666, ex);
						iserror = true;
						errorShow();
					});
				break;
			case 5:
				setIsBlock(true);
				for (const ss of settingStorage) {
					await settingSave({...ss,partnerId:partner._id});
				}
				await settingsGet();
				break;
			case 6:
				if (isEdit) {
					data = {
						name:deliveryAreaName,
						description:deliveryAreaDescription,
						price:deliveryAreaPrice,
						priceFree:deliveryAreaPriceFree,
						minimumAmount:deliveryAreaMinimumAmount,
						maxTime:deliveryAreaMinTime,
						minTime:deliveryAreaMaxTime,
						alwaysPaid:deliveryAreaAlwaysPaid,
						area:area,
						color:deliveryAreaColor,
						workTime:deliveryAreaWorkTime,
						status:commonStatus.ACTIVE
					};
					if (Utils.empty(data.name)) {
						errorShow('Необходимо заполнить название зоны доставки');
						return;
					}
					if (Utils.empty(data.area)) {
						errorShow('Необходимо заполнить область зоны доставки');
						return;
					}
					setIsBlock(true);
					const res6 = deliveryAreaId ?
							await DeliveryAreas.update(deliveryAreaId, data).catch((ex) => {
								iserror = true;
								console.log(666, ex);
								errorShow();
							})
						:
							await DeliveryAreas.add(data).catch((ex) => {
								iserror = true;
								console.log(666, ex);
								errorShow();
							});
					if (res6) {
						await stepUpdate(step);
						successShow(res6.message);
						await areasGet();
					}
				}
				break;
			case 7:
				if (isEdit) {
					data = {
						name:productName,
						link:Utils.translit(productName),
						description:productDescription,
						categoryId:productCategoryId,
						price:productPrice,
						priceFull:fullPriceGet(),
						discount:productDiscount,
						weight:productWeight,
						measureType:productMeasureType,
						points:[{id:point.id,name:point.name}],
						days:[true,true,true,true,true,true,true],
						deliveryType:[deliveryType.DELIVERY,deliveryType.PICKUP],
						sources:[sourceType.HALL,sourceType.DISPATCHER,sourceType.WEBSITE,sourceType.IOS,sourceType.ANDROID,sourceType.TELEGRAM,sourceType.VK,sourceType.ROBOT,sourceType.KIOSK],
						status:commonStatus.ACTIVE
					};
					if (Utils.empty(data.name)) {
						errorShow('Необходимо заполнить название блюда');
						return;
					}
					if (Utils.empty(data.categoryId)) {
						errorShow('Необходимо выбрать категорию');
						return;
					}
					if (Utils.empty(data.price)) {
						errorShow('Необходимо указать цену блюда');
						return;
					}
					if (data.points.length === 0) {
						errorShow('Необходимо выбрать филиалы');
						return;
					}
					if (productImages.length === 0) {
						errorShow('Необходимо добавить фотографии блюда');
						return;
					}
					if (Utils.empty(data.weight)) {
						errorShow('Необходимо указать объем или вес блюда');
						return;
					}
					if (Utils.empty(data.measureType)) {
						errorShow('Необходимо указать единицу измерения');
						return;
					}
					setIsBlock(true);
					const res7 = productId ?
							await Products.update(productId, data).catch((ex) => {
								iserror = true;
								console.log(666, ex);
								errorShow(ex.message);
							})
						:
							await Products.add(data).catch((ex) => {
								iserror = true;
								console.log(666, ex);
								errorShow(ex.message);
							});
					if (res7) {
						await imagesUpload(productId||res7.id);
						await imagesUpdate(productId||res7.id);
						await stepUpdate(step);
						successShow(res7.message);
						await productsGet();
					}
				}
				break;
			case 8:
				modalFinishOpen();
				return;
		};
		if (!iserror) {
			if (isEdit) {
				setIsEdit(false);
			} else {
				if (step !== 6 && step !== 7) await stepUpdate(step);
				await dataGet();
				show(step);
			}
		}
		setIsBlock(false);
	};
	const back = async () => {
		if (isEdit) {
			setIsEdit(false);
			return;
		}
		setStep(step - 1);
	};
	const skip = () => show(step);
	const done = () => {
		ls('checklist', null);
		window.location.href = '/';
	};
	const remove = () => step === 6 || step === 7 ? modalDeleteOpen() : null;
	const modalRemove = async () => {
		if (step === 6) {
			await DeliveryAreas.remove(deliveryAreaId).catch(ex => {
				console.log(666, ex);
				errorShow(ex.message);
			});
			areasGet();
		}
		if (step === 7) {
			await Products.remove(productId).catch((ex) => {
				console.log(666, ex);
				errorShow(ex.message);
			});
			productsGet();
		}
		modalDeleteClose();
		setIsEdit(false);
	};
	const show = (step) => {
		switch (step) {
			case 0:
				setName(name||partner.name);
				setDescription(description||partner.description);
				setPhone(phone||partner.contacts?.phone);
				setAddress(address||partner.contacts?.address);
				break;
			case 1:
				setOfficialName(officialName||partner.requisites?.name);
				setOfficialAddress(officialAddress||partner.requisites?.address);
				setInn(inn||partner.requisites?.inn);
				setKpp(kpp||partner.requisites?.kpp);
				setOgrn(ogrn||partner.requisites?.ogrn);
				setOkpo(okpo||partner.requisites?.okpo);
				setOkved(okved||partner.requisites?.okved);
				setBankName(bankName||partner.requisites?.bank?.name);
				setBankBik(bankBik||partner.requisites?.bank?.bik);
				setBankCorrespondent(bankCorrespondent||partner.requisites?.bank?.correspondent);
				setBankAccount(bankAccount||partner.requisites?.bank?.account);
				break;
			case 2:
				const isSubDomainDisabled = partner.domains?.subdomain?.status === commonStatus.IN_ACTIVE, isDomainParked = partner.domains?.domain?.status === commonStatus.ACTIVE;
				const d = `.${API.domain}`;
				setDomain(domain||partner.domains?.domain?.name);
				const subdomain = (subDomain||(partner.domains?.subdomain?.name))?.replace(d,'');
				setSubDomain(subdomain ? subdomain + (isSubDomainDisabled?d:'') : '');
				if (isSubDomainDisabled) setIsSubDomainDisabled(true);
				if (isDomainParked) setIsDomainParked(true);
				break;
			case 3:
				setPointName(pointName||partner.point?.name);
				setCityId(cityId||partner.point?.cityId);
				setPointAddress(pointAddress||partner.point?.contacts?.address);
				setPointPhone(pointPhone||partner.point?.contacts?.phone||partner.user.phone);
				setPointEmail(pointEmail||partner.point?.contacts?.email||partner.user.email);
				setPointLatitude(pointLatitude||partner.point?.coordinates?.latitude);
				setPointLongitude(pointLongitude||partner.point?.coordinates?.longitude);
				setArea(area||partner.point?.area);
				setPointWorkTimeDescription(pointWorkTimeDescription||partner.point?.workTimeDescription);
				setPointWorkTime(workTimePrepare(partner.point?.workTime)||[]);
				setAreas(partner.point?.areas||[]);
				break;
			case 5:
				break;
			case 6:
				break;
			case 7:
				const t = tariffs.find(f => f._id === partner.tariff.id);
				setTariff(t);
				break;
		};
		setStep(step + 1);
	};
	const fullPriceGet = (p) => {
		const price = parseFloat(p||productPrice) || 0;
		const discount = parseFloat(productDiscount) || 0;
		return price - (price * discount / 100);
	};
	const searchBank = async (e) => {
		const bank = e.target.value.replace(/[^0-9]/g, '');
		setBank(bank);
		if (bank.length < 3) return;
		const res = await Banks.get(bank).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
		});
		if (res) setBanks(res.suggestions);
	};
	const bankSelect = (bank) => {
		setBankName(bank.unrestricted_value);
		setBankAddress(bank.data.address.value);
		setBankBik(bank.data.bic);
		setBankInn(bank.data.inn);
		setBankCorrespondent(bank.data.correspondent_account);
		setBanks([]);
	};
	const domainSet = (e) => {
		setDomain(e.target.value.replace(/[^a-zA-Z0-9-._]/g, '').toLowerCase());
		setIsDomainParked(false);
	}
	const subDomainSet = (e) => setSubDomain(e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase());
	const domainCheck = async (domain, isSubDomain) => {
		const res = await Partners.domains.check({domain,isSubDomain}).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
		});
		if (res) successShow(res.message);
	};
	const cityCoordinatesGet = () => {
		const city = cities.find(f => f._id === cityId);
		const latitude = city?.coordinates?.latitude||MAP.defCoords[0], longitude = city?.coordinates?.longitude||MAP.defCoords[1];
		return [latitude,longitude];
	};
	const startEdit = () => {
		if (polygons) {
			if (area && area.length) polygons.editor.startEditing();
			else polygons.editor.startDrawing();
			polygons.geometry.events.add('change', (e) => setArea(e.get('newCoordinates')));
		}
		setIsMapEdit(true);
	};
	const stopEdit = () => {
		if (polygons) polygons.editor.stopEditing();
		setIsMapEdit(false);
	}
	const mapClose = () => {
		stopEdit();
		closeMap();
	};
	const mapLoaded = (ymaps) => {
		setTimeout(() => {
			if (map) {
				if (area && area.length) {
					const y = ymaps.geoQuery(map.geoObjects);
					map.setBounds(y.getBounds(), {zoomMargin: [30, 30, 30, 30]});
					map.setZoom(map.getZoom());
				}
			}
		}, 1000)
	};
	const handleCity = (e) => {
		setArea(null);
		setCityId(e.target.value);
	};
	const addressGet = async (e) => {
		const address = e.target.value;
		setPointAddress(address);
		const addresses = await Addresses.get(address, cityId).catch((ex) => console.log(666, ex));
		setAddresses(addresses);
	};
	const selectAddress = (address) => {
		setPointAddress(address.address);
		setPointLatitude(address.latitude);
		setPointLongitude(address.longitude);
		setAddresses([]);
	};
	const staticMapGet = (area, color) => {
		const poligons = [];
		const c = color ? color.replace('#','') : 'ff0000';
		const size = '400,300';
		area.forEach((v) => {
			const p = [];
			v.forEach((v) => {
				p.push(v[1]);
				p.push(v[0]);
			});
			poligons.push(`c:${c},f:${c}30,w:3,${p.join(',')}`);
		});
		const url = `${MAP.URLS.static}&size=${size}&pl=${poligons.join('~')}&apikey=${MAP.keys.YStatic}`;
		return <img src={url} alt="" />
	};
	const workTimePrepare = (workTime) => {
		workTime = workTime||[];
		const wt = [];
		Utils.weekDayName.full.forEach((v,i) => {
			const start = workTime.find(f => f.day === i)?.start||null;
			const end = workTime.find(f => f.day === i)?.end||null;
			wt.push({day:i,start:start,end:end});
		});
		return wt;
	};
	const areaMapOpen = () => {
		if (!cityId) {
			errorShow('Необходимо выбрать город');
			return;
		}
		openMap();
	};
	const helpGet = async (code) => {
		helpHide();
		const res = await HelpDesks.get.code(code).catch((ex) => {
			console.log(666, ex);
		});
		if (res) setHelp(res);
	};
	const helpHide = () => setHelp(null);
	const helpStop = (e) => e.stopPropagation();
	const helpRender = (code) => <>
		<img src={imgHelp} alt="" onClick={() => helpGet(code)} />
		{help && help.code === code ?
				<div className="help-desk" onClick={helpStop}>
					<div className="help-desk-content">
						{help.short}
					</div>
					<div className="help-desk-footer">
						<a href={`${API.help}${help.path}`} target="_blank">подробнее</a>
					</div>
					<img src={imgClose} className="help-desk-close" onClick={helpHide} alt="" />
				</div>
			: null}
	</>;
	const scrollTo = (id) => {
		const el = document.querySelector(`#${id}`);
		if (el) {
			const s = document.getElementsByClassName('section');
			for (let i = 0; i < s.length; i++) s[i].style = null;
			el.scrollIntoView({behavior:'smooth'});
			el.style.background = '#fcfccc';
			setTimeout(() => {
				el.style.background = null;
				el.style.transition = 'background 1.5s ease-in-out';
			}, 1000);
		}
	};
	const settingChange = (setting) => {
		if (settingStorage.find(f => f.code === setting.code) === undefined) {
			if (!Utils.empty(setting.value))
				settingStorage.push(setting);
		}
		else {
			const ss = settingStorage.find(f => f.code === setting.code);
			if (ss) {
				const s = settings.find(f => f.code === setting.code);
				if (Utils.empty(s.value) && Utils.empty(setting.value)) {
					const ss2 = settingStorage.filter(f => f.code !== setting.code);
					settingStorage = ss2;
				} else {
					settingStorage.forEach((v,i) => {
						if (v.code === setting.code) settingStorage[i] = setting;
					});
				}
			}
		}
	};
	const handleProductCategory = (e) => setProductCategoryId(e.target.value);
	const productCategoryAddShow = () => modalCategoryOpen();
	const categorySave = async () => {
		const data = {
			name:categoryName,
			points:[{id:point.id,name:point.name}],
			link:Utils.translit(categoryName),
			status:commonStatus.ACTIVE
		};
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название категории');
			return;
		}
		const res = await Categories.add(data).catch((ex) => {
			console.log(666, ex);
			errorShow();
		});
		if (res) {
			successShow(res.message);
			await categoriesGet();
			setProductCategoryId(res.id);
			modalCategoryHide();
		}
	};
	const imageError = (e) => e.target.src = imgNoPhoto;
	const imageUrlGet = (productId, filename) => `${API.assets}partners/${partner._id}/products/${productId}/${filename}`;
	const imageMainSet = (index) => setProductImageMainIndex(index);
	const handleProductImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setProductImages([...productImages, r.target.result]);
		reader.readAsDataURL(file);
	};
	const imagesUpdate = async (id) => {
		const images = [];
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) images.push(`${id}-${i}.${Utils.imageExtGet(v)}`);
		});
		if (images.length === 0) return;
		const image = images[productImageMainIndex] || images[0];
		await Products.image.update(id, {images,image}).catch((ex) => console.log(666, ex));
	};
	const imagesUpload = async (id) => {
		productImages.forEach((v,i) => {
			const data = imagePrepare(v);
			if (data) {
				const type = Utils.imageExtGet(v);
				Products.image.add(id, {data,type,index:i}).catch((ex) => console.log(666, ex));
			}
		});
	};
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const handleMeasureType = (e) => setProductMeasureType(parseInt(e.target.value));
	const modalCategoryHide = () => modalCategoryClose();
	const deliveryAreaEdit = (item) => {
		setCityId(cityId);
		setDeliveryAreaId(item?._id||null);
		setDeliveryAreaName(item?.name||'');
		setDeliveryAreaDescription(item?.description||'');
		setDeliveryAreaPrice(item?.prices.price||'');
		setDeliveryAreaPriceFree(item?.prices.priceFree||'');
		setDeliveryAreaMinimumAmount(item?.prices.minimumAmount||'');
		setDeliveryAreaMaxTime(item?.times?.max||'');
		setDeliveryAreaMinTime(item?.times?.min||'');
		setDeliveryAreaAlwaysPaid(item?.alwaysPaid||false);
		setDeliveryAreaColor(item?.properties.color||'#00ff00');
		setDeliveryAreaWorkTime(workTimePrepare(item?.workTime)||[]);
		setArea(item && item.area ? item.area : []);
		setIsEdit(true);
	};
	const productEdit = async (item) => {
		setProductId(item?._id||null);
		setProductName(item?.name||'');
		setProductDescription(item?.description||'');
		setProductCategoryId(item?.categoryId||'');
		setProductPrice(item?.prices.price||'');
		setProductPriceFull(item?.prices.priceFull||'');
		setProductDiscount(item?.discounts.discount||'');
		setProductWeight(item?.properties.weight||'');
		setProductMeasureType(item?.properties.measureType||'');
		setProductImages(item ? item?.images.map((v) => imageUrlGet(item._id, v)) : []);
		setProductImageMainIndex(item ? item.images.findIndex(f => f === item.image) : null);
		setIsEdit(true);
	};
	const categoryGet = (id) => productCategories.find(f => f._id === id)?.name;
	const removeNameGet = () => {
		if (step === 6) return deliveryAreaName;
		if (step === 7) return productName;
	};
	const totop = () => {
		const el = document.querySelector('#s0');
		if (el) el.scrollIntoView({behavior:'smooth'});
	};
	const techNameSet = () => {
		if (step === 1) setIsPartnerTechName(false);
		if (step === 4) setIsPointTechName(false);
		modalTechNameConfirmClose();
	};
	const stepUpdate = async (step) => {
		const idx = step - 1;
		await AccountCheckLists.set(idx)
			.then(() => {
				checkSteps[idx] = true;
				setCheckSteps([...checkSteps]);
				ls('checklist', checkSteps);
			})
			.catch((ex) => console.log(666, ex));
	};
	const wtSet = (wt, desc) => {
		setPointWorkTime(wt);
		setPointWorkTimeDescription(desc);
	};
	return (
		<>
			<div className="setup-container-container" onClick={helpHide}>
				{isError || (loading && step === 1) ? null :
					<>
						{steps[step-1].fields ?
								<div className="setup-container-menu">
									<ul>
										{steps[step-1].fields.map((v,i) => {
											if (step === 6) {
												if (!isEdit) {
													if (i === 0) v.title = 'Добавить зону';
													else if (i === 1) v.title = 'Зоны доставки';
													else return null;
												}
											}
											if (step === 7) {
												if (!isEdit) {
													if (i === 0) v.title = 'Добавить товар';
													else if (i === 1) v.title = 'Список товаров';
													else return null;
												}
											}
											return <li key={i} onClick={() => scrollTo(`s${v.id||(i+1)}`)}>{v.title}</li>;
										})}
									</ul>
								</div>
							: null}
						{step === 2 || step === 4 || step === 5 || step === 6 || step === 7 || step === 8 ?
								<div className="setup-container-totop" onClick={totop}>↑</div>
							: null}
					</>
				}
				<div className="setup-container">
					<div className="header">
						<img src={imgLogo} alt="" />
						<div className="steps">
							<label>Мастер настройки</label>
							<div>Шаг <b>{step}</b> из <span>{totalSteps}</span></div>
						</div>
					</div>
					{loading ?
							<div className="loader">
								<Loader />		
							</div>
						:
							(isError ? <Error /> :
								<>
									<div className="main">
										{step === 1 ?
												<>
													<div className="section" id="s1">
														<h4>
															Данные компании
															{helpRender('с-чего-начать')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindName} placeholder="Название компании" />
														</div>
														<div className="product-edit-row">
															<textarea placeholder="Описание" {...bindDescription} className="lite"></textarea>
														</div>
													</div>
													<div className="section" id="s2">
														<h4>
															Контакты
															{helpRender('web-сайт')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindAddress} placeholder="Адрес" />
														</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон" maskPlaceholder={null} {...bindPhone} />
														</div>
													</div>
												</>
											: null}

										{step === 2 ?
												<>
													<div className="section" id="s1">
														<h4>
															Реквизиты
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialName} placeholder="Официальное название" />
														</div>
														<div className="product-edit-row">
															<input type="text" {...bindOfficialAddress} placeholder="Юридический адрес" />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="inn" className="label-middle">ИНН</label>
															<input id="inn" type="text" {...bindInn} placeholder="ИНН" maxLength={12} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="kpp" className="label-middle">КПП</label>
															<input id="kpp" type="text" {...bindKpp} placeholder="КПП" maxLength={9} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="ogrn" className="label-middle">ОГРН</label>
															<input id="ogrn" type="text" {...bindOgrn} placeholder="ОГРН" maxLength={15} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okpo" className="label-middle">ОКПО</label>
															<input id="okpo" type="text" {...bindOkpo} placeholder="ОКПО" maxLength={10} />
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="okved" className="label-middle">ОКВЕД</label>
															<input id="okved" type="text" {...bindOkved} placeholder="ОКВЕД" maxLength={6} />
														</div>
													</div>
													<div className="section" id="s2">
														<div className="bank-requisites">
															{banks && banks.length ? 
																<div className="banks-list">
																	{banks.map((v,i) => <div key={i} className="bank" onClick={() => bankSelect(v)}>{v.unrestricted_value}</div>)}
																</div> : null}
															<h4>
																Банковские реквизиты
																{helpRender('')}
															</h4>
															<div className="product-edit-row">
																<input type="text" placeholder="Поиска банка по ИНН, БИК" onChange={searchBank} />
															</div>
														</div>
														<div className="product-view-row product-view-row-simple">
															<b>{bankName}</b>
															<div>{bankAddress}</div>
														</div>
														<div className="product-view-row product-view-row-simple product-edit-row-oneline">
															<label>БИК</label>
															<div>{bankBik}</div>
														</div>
														<div className="product-view-row product-view-row-simple product-edit-row-oneline">
															<label>ИНН</label>
															<div>{bankInn}</div>
														</div>
														<div className="product-view-row product-view-row-simple product-edit-row-oneline">
															<label>Корреспондентский счет</label>
															<div>{bankCorrespondent}</div>
														</div>
														<div className="product-edit-row product-edit-row-oneline">
															<label htmlFor="account" className="label-middle">Расчетный счет</label>
															<input id="account" type="text" {...bindBankAccount} placeholder="Расчетный счет" maxLength={20} />
														</div>
													</div>
												</>
											: null}

										{step === 3 ?
												<>
													<div className="section" id="s1">
														<h4>
															Бесплатный поддомен на {API.domain}
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindSubDomain} placeholder={`поддомен.${API.domain}`} onChange={subDomainSet} maxLength={20} disabled={isSubDomainDisabled} />
															{isSubDomainDisabled ? <img src={imgCheckGreenOn} alt="" className="product-edit-check-icon" /> : <div className="product-edit-check" onClick={() => domainCheck(subDomain, true)}>проверить</div>}
															<div className="product-edit-row-notice">
																<div>Обратите внимание, что поддомен можно выбрать один раз. Изменение возможно только через обращение в поддержку сервиса.</div>
															</div>
														</div>
														</div>
													<div className="section" id="s2">
														<h4>
															Домен
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindDomain} placeholder="Домен" onChange={domainSet} />
															{isDomainParked ? <img src={imgCheckGreenOn} alt="" className="product-edit-check-icon" /> : <div className="product-edit-check" onClick={() => domainCheck(domain, false)}>проверить</div>}
															<div className="product-edit-row-notice">
																<pre dangerouslySetInnerHTML={{__html:`Укажите NS-серверы\r\nns1.timeweb.ru\r\nns2.timeweb.ru\r\nns3.timeweb.org\r\nns4.timeweb.org`}} />
																<div>Данные обновятся через 24 часа, домен при этом будет недоступен.</div>
															</div>
														</div>
													</div>
												</>
											: null}

										{step === 4 ?
												<>
													<div className="section" id="s1">
														<h4>
															Филиал
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindPointName} placeholder="Название филиала" />
														</div>
														<div className="product-edit-row">
															<div className="select select-wide">
																<select onChange={handleCity} value={cityId}>
																	<option value="">Выберите город</option>
																	{cities.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
																</select>
															</div>
														</div>
													</div>
													<div className="section" id="s2">
														<h4>
															Контакты
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															<input type="text" {...bindPointAddress} placeholder="Адрес филиала" onChange={addressGet} />
														</div>
														<div className="product-edit-row-addresses">
															{addresses.length ?
																<div className="point-addresses-list">
																	{addresses.map((v,i) => <div key={i} onClick={() => selectAddress(v)}>{v.address}</div>)}
																</div> : null}
															</div>
														<div className="product-edit-row">
															<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон филиала" maskPlaceholder={null} {...bindPointPhone} />
														</div>
														<div className="product-edit-row">
															<input type="email" {...bindPointEmail} placeholder="Электронная почта" />
														</div>
													</div>
													<div className="section" id="s3">
														<h4 className="no-margin">
															Область обслуживания
															{helpRender('')}
														</h4>
														<div className="product-edit-row">
															{area && area.length ? staticMapGet(area) : null}
															<div className="product-edit-btn" onClick={areaMapOpen}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
														</div>
													</div>
													<div className="section" id="s4">
														<h4 className="no-margin">
															Время работы
															{helpRender('')}
														</h4>
														<WorkTime workTime={pointWorkTime} onChange={wtSet} />
													</div>
												</>
											: null}

										{step === 5 ?
												<>
													<h3 id="s0">Оформление магазина</h3>
													{settings.filter(f => settingsDesign.includes(f.code)).map((v,i) => <div key={i} className="section" id={`s${i+1}`}>
														<Setting setting={{...v,partnerId:partner._id}} isEditShow={true} isSaveExt={true} onChange={(s) => settingChange(s)} helpPanel={helpRender(steps[step-1].fields[i].help)} />
													</div>)}
													<h3>Настройки магазина</h3>
													{settings.filter(f => settingsSettings.includes(f.code)).map((v,i) => <div key={i} className="section" id={`s${i+settingsDesign.length-1}`}>
														<Setting setting={{...v,partnerId:partner._id}} isEditShow={true} isSaveExt={true} onChange={(s) => settingChange(s)} helpPanel={helpRender(steps[step-1].fields[i].help)} />
													</div>)}
												</>
											: null}

										{step === 6 ?
											(isEdit ?
													<>
														<div className="section" id="s1">
															<h4>
																Зона доставки
																{helpRender('')}
															</h4>
															<div className="product-edit-row">
																<input type="text" {...bindDeliveryAreaName} placeholder="Название зоны" />
															</div>
															<div className="product-edit-row">
																<textarea placeholder="Описание" {...bindDeliveryAreaDescription} className="lite"></textarea>
															</div>
														</div>
														<div className="section" id="s2">
															<h4 className="no-margin">
																Область обслуживания
																{helpRender('')}
															</h4>
															<div className="product-edit-row">
																{area && area.length ? staticMapGet(area, deliveryAreaColor) : null}
																<div className="product-edit-btn" onClick={() => openMap()}>{area && area.length ? 'Изменить' : 'Добавить'}</div>
															</div>
														</div>
														<div className="section" id="s3">
															<h4 className="no-margin">
																Условия
																{helpRender('')}
															</h4>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="price" className="label-middle">Стоимость доставки</label>
																<input id="price" {...bindDeliveryAreaPrice} type="text" placeholder="0" maxLength={3} />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="priceFree" className="label-middle">Бесплтано от</label>
																<input id="priceFree" {...bindDeliveryAreaPriceFree} type="text" placeholder="0" maxLength={5} />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="minimumAmount" className="label-middle">Мин. сумма заказа</label>
																<input id="minimumAmount" {...bindDeliveryAreaMinimumAmount} type="text" placeholder="0" maxLength={6} />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="maxTime" className="label-middle">Макс. время доставки (мин.)</label>
																<input id="maxTime" {...bindDeliveryAreaMaxTime} type="text" placeholder="0" maxLength={3} />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="minTime" className="label-middle">Мин. время доставки (мин.)</label>
																<input id="minTime" {...bindDeliveryAreaMinTime} type="text" placeholder="0" maxLength={3} />
															</div>
														</div>
														<div className="section" id="s4">
															<h4 className="no-margin">
																Время работы
																{helpRender('')}
															</h4>
															<WorkTime workTime={pointWorkTime} onChange={(wt) => setDeliveryAreaWorkTime(wt)} descriptionHide={true} />
														</div>
														<div className="section" id="s5">
															<h4 className="no-margin">
																Дополнительные параметры
																{helpRender('')}
															</h4>
															<div className="product-edit-row product-edit-row-oneline">
																<label>Всегда платная доставка</label>
																<img src={deliveryAreaAlwaysPaid ? imgCheckOn : imgCheckOff} alt="" onClick={() => setDeliveryAreaAlwaysPaid(!deliveryAreaAlwaysPaid)} />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="color" className="label-middle">Цвет</label>
																<div className="input">
																	<input type="color" id="color" {...bindDeliveryAreaColor} className="color" />
																</div>
															</div>
														</div>
													</>
												:
													<>
														<div className="section product-edit-row-oneline" id="s1">
															<div className="product-edit-row">
																<button type="button" className="product-edit-btn" onClick={() => deliveryAreaEdit()}>Добавить зону</button>
															</div>
															{helpRender('')}
														</div>
														<div className="section" id="s2">
															<h4 className="no-margin">
																Зоны доставки
																{helpRender('')}
															</h4>
															<div className="product-view-list">
																{deliveryAreas.map((v,i) => <div key={i} className="product-view-list-item" onClick={() => deliveryAreaEdit(v)}>
																	<div className="cell-name">{v.name}</div>
																	<div className="cell cell-amount cell-amount-first">
																		{i === 0 ? <span>Цена доставки</span> : null}
																		{v.prices.price?`${v.prices.price} ₽`:'–'}
																	</div>
																	<div className="cell cell-amount">
																		{i === 0 ? <span>Бесплатная доставка</span> : null}
																		{v.prices.priceFree?`${v.prices.priceFree} ₽`:'–'}
																	</div>
																	<div className="cell cell-amount">
																		{i === 0 ? <span>Минимальная сумма</span> : null}
																		{v.prices.minimumAmount?`${v.prices.minimumAmount} ₽`:'–'}
																	</div>
																	<div className="cell cell-color">
																		<div className="color" style={{backgroundColor:v.properties.color}}></div>
																	</div>
																</div>)}
															</div>
														</div>
													</>
											)
												: null}

										{step === 7 ?
											(isEdit ?
													<>
														<div className="section" id="s1">
															<h4>
																	Описание блюда
																	{helpRender('')}
																</h4>
															<div className="product-edit-row">
																<input type="text" {...bindProductName} placeholder="Название блюда" />
															</div>
															<div className="product-edit-row">
																<textarea placeholder="Описание" {...bindProductDescription}></textarea>
															</div>
														</div>
														<div className="section" id="s2">
															<h4>
																Категория блюда
																{helpRender('')}
															</h4>
															<div className="product-edit-row product-edit-row-oneline product-edit-row-plus">
																<div className="select select-wide">
																	<select onChange={handleProductCategory} value={productCategoryId}>
																		<option value="">Выберите категорию</option>
																		{productCategories.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
																	</select>
																</div>
																<img src={imgPlusButton} alt="" className="categoty-add" onClick={() => productCategoryAddShow()} />
															</div>
														</div>
														<div className="section" id="s3">
															<h4 className="no-margin">
																Цена / Скидка
																{helpRender('')}
															</h4>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="productPrice" className="label-middle">Цена блюда</label>
																<input id="productPrice" {...bindProductPrice} type="text" placeholder="0" />
															</div>
															<div className="product-edit-row product-edit-row-oneline">
																<label htmlFor="productDiscount" className="label-middle">Процент скидки</label>
																<input id="productDiscount" {...bindProductDiscount} type="text" placeholder="0" maxLength={2} />
															</div>
														</div>
														<div className="section" id="s4">
															<h4>
																Фотографии
																{helpRender('')}
															</h4>
															<div className="product-edit-row">
																{productImages.length ?
																		<div className="product-edit-images">
																			{productImages.map((v,i) => <div key={i} className={`product-edit-image${productImageMainIndex===i?' product-edit-image-main':''}`}  onClick={() => imageMainSet(i)}>
																				<img src={v} alt="" />
																				<div className="delete" onClick={() => setProductImages(productImages.filter(f => f !== v))}></div>
																			</div>)}
																		</div>
																	: null
																}
																<label>
																	<div className="product-edit-btn">
																		Добавить фото
																		<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleProductImage} />
																	</div>
																</label>
															</div>
														</div>
														<div className="section" id="s5">
															<h4>
																Объем / Вес
																{helpRender('')}
															</h4>
															<div className="product-edit-row product-edit-row-oneline">
																<input type="text" {...bindProductWeight} placeholder="Объем / Вес" />
																<div className="select">
																	<select onChange={handleMeasureType} value={productMeasureType}>
																		<option value=""></option>
																		{measureTypeSmallName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
																	</select>
																</div>
															</div>
														</div>
													</>
												:
													<>
														<div className="section product-edit-row-oneline" id="s1">
															<div className="product-edit-row">
																<button type="button" className="product-edit-btn" onClick={() => productEdit()}>Добавить товар</button>
															</div>
															{helpRender('')}
														</div>
														<div className="section" id="s2">
															<h4 className="no-margin">
																Список товаров
																{helpRender('')}
															</h4>
															<div className="product-view-list">
																{products.map((v,i) => <div key={i} className="product-view-list-item" onClick={() => productEdit(v)}>
																	<div className="cell-image">
																		<img src={imageUrlGet(v._id, v.image)} alt="" onError={imageError} />
																	</div>
																	<div className="cell-name">
																		{v.name}
																		<span>{categoryGet(v.categoryId)}</span>
																	</div>
																	<div className="cell cell-price">
																		{v.discounts.discount ? <><s>{v.prices.price}</s> {v.prices.priceFull}</> : v.prices.priceFull} ₽
																	</div>
																	<div className="cell cell-measure">{v.properties.weight} {measureTypeSmallName[v.properties.measureType]}</div>
																</div>)}
															</div>
														</div>
													</>
											)
												: null}

										{step === 8 ?
												<>
													<div className="section">
														<h4 className="no-margin margin-bottom">
															Текущий тариф
															{helpRender('')}
														</h4>
														{tariff ?
																<>
																	<div className="product-view-list">
																		<div>
																			<b>{tariff.name}</b> на {Orfo.counters.days(tariff.period)}
																		</div>
																		<p>{tariff.description}</p>
																	</div>
																	<div className="product-view-list">
																		<b>Возможности</b>
																		<ul>{tariff.features.map((v,i) => <li key={i}>{v}</li>)}</ul>
																	</div>
																</>
															: null}
													</div>
													<div className="section">
														<h4 className="no-margin margin-bottom">
															Чек-лист
															{helpRender('')}
														</h4>
														{checkSteps.map((v,i) => <div key={i} className="product-view-list-check">
															{v ? <img src={imgCheckGreenOn} alt="" /> : <img src={imgCheckGreenOff} alt="" />}
															<div>{steps[i].title}</div>
														</div>)}
														<div className="product-view-list-check-summary">
															<div>
																<b>Внимание!</b>
																<p>После завершения настройки, вы сможете изменить данные в личном кабинете, а так же дополнительно настроить Акции, Правила корзины, События и тд.</p>
															</div>
														</div>
													</div>
												</>
											: null}
									</div>
								</>
							)
					}
					{isError || (loading && step === 1) ? null :
						<div className="footer">
							{isEdit ?
									<>
										<div className="buttons">
											<button type="button" onClick={back}>Отмена</button>
											{(step === 6 && deliveryAreaId) || (step === 7 && productId) ? <button type="button" onClick={remove} className="btn-delete">Удалить</button> : null}
										</div>
										{isBlock ? <div className="btn-save btn-wait">Сохранение</div> : <button type="button" onClick={next} className="btn-save">Сохранить</button>}
									</>
								:
									<>
										<div className="buttons">
											<button type="button" onClick={back} className="btn-cancel" disabled={step===1}>Назад</button>
											{step === 4 && (Utils.empty(partner?.point?.cityId) || Utils.empty(cityId)) || step === 6 || step === 7 || step === 8 ? null : <button type="button" onClick={skip} className="btn-skip">Пропустить</button>}
										</div>
										{isBlock ? <div className="btn-save btn-wait">Сохранение</div> : <button type="button" onClick={next} className={step===totalSteps?'btn-done':'btn-save'}>{step === totalSteps ? 'Завершить' : 'Далее'}{step === totalSteps ? null : <span>{steps[step].title}</span>}</button>}
									</>
							}
						</div>
					}
				</div>
			</div>
			<Alert />
			<ModalFinish>
				<div className="modal modal-layout modal-finish">
					<div className="header">
						<h4>Внимание!</h4>
						<img src={imgClose} alt="" onClick={modalFinishClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите закончить настройки?</p>
					<p>Вы всегда можете изменить данные в личном кабинете.</p> 
					<div className="buttons">
						<button type="button" onClick={modalFinishClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={done} className="btn-accept btn-done">Завершить</button>
					</div>
				</div>
			</ModalFinish>
			<ModalDelete>
				<div className="modal modal-layout">
					<div className="header">
						<h4>Удаление</h4>
						<img src={imgClose} alt="" onClick={modalDeleteClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите удалить позицию <b>{removeNameGet()}</b>?</p>
					<div className="buttons">
						<button type="button" onClick={modalDeleteClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={modalRemove} className="btn-accept btn-delete">Удалить</button>
					</div>
				</div>
			</ModalDelete>
			<ModalTechNameConfirm>
				<div className="modal modal-layout">
					<div className="header">
						<h4>Внимание!</h4>
						<img src={imgClose} alt="" onClick={modalTechNameConfirmClose} className="btn-close" />
					</div>
					<p>Вы уверены что хотите оставить техническое имя <b>{step===0?'PARTNER':'POINT'}_{partner?.number}</b>?</p>
					<div className="buttons">
						<button type="button" onClick={modalTechNameConfirmClose} className="btn-cancel">Отменить</button>
						<button type="button" onClick={techNameSet} className="btn-accept">Да</button>
					</div>
				</div>
			</ModalTechNameConfirm>
			<ModalMap>
				<div className="modal setup-modal-map">
					<div className="map-container">
						<YMaps query={{apikey:MAP.keys.Yandex}}>
							<Map
								instanceRef={m => map = m}
								onLoad={mapLoaded}
								defaultState={{center:cityCoordinatesGet(),zoom:12}}
								modules={['geoObject.addon.editor','geoQuery']}
								width={'100%'} height={'100%'}
							>
								<ZoomControl />
								<Polygon
									instanceRef={p => polygons = p}
									geometry={area||[]}
									options={{
										editorDrawingCursor:'crosshair',
										strokeOpacity:.5,
										strokeWidth:3,
										fillColor:step===6?deliveryAreaColor:color,
										opacity:.5
									}}
									properties={{hintContent:name}}
									modules={['geoObject.addon.hint']}
									onLoad={startEdit} />
							</Map>
						</YMaps>
						<div className="map-icon map-close">
							<img src={imgClose} alt="" onClick={mapClose} />
						</div>
						<div className="map-icon map-edit">
							<img src={isMapEdit ? imgEditStop : imgEdit} alt="" onClick={() => isMapEdit ? stopEdit() : startEdit()} />
						</div>
					</div>
				</div>
			</ModalMap>
			<ModalCategory>
				<div className="modal setup-modal-category">
					<div className="header">
						<h4>Добавить категорию</h4>
						<img src={imgClose} alt="" onClick={modalCategoryHide} className="btn-close" />
					</div>
					<div className="product-edit">
						<div className="container-inner">
							<div className="list">
								<div className="product-edit-row">
									<input type="text" {...bindCategoryName} autoFocus={true} placeholder="Название категории" />
								</div>
							</div>
						</div>
						<div className="product-edit-footer">
							<button type="button" onClick={modalCategoryHide} className="btn-cancel">Отменить</button>
							<button type="button" onClick={categorySave} className="btn-save btn-accept">Сохранить</button>
						</div>
					</div>
				</div>
			</ModalCategory>
		</>
	);
}

export default SetupScreen;