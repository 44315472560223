import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

// plug-ins
import {useModal} from 'react-hooks-use-modal';
import moment from 'moment';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import Layout from '../../../Components/Layout';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Accordion from '../../../Components/Accordion';

// models
import {Orders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { orderFeedbackAction, orderFeedbackActionName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';


// start
const FeedbacksScreen = () => {
	const history = useHistory();
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [orders, setOrders] = useState(null);
	const [ordersAll, setOrdersAll] = useState([]);
	const [order, setOrder] = useState(null);
	const [number, setNumber] = useState(null);
	const [answer, setAnswer] = useState(null);
	const [search, setSearch] = useState('');
	const [id, setId] = useState(null);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await ordersGet();
		};
		await dataGet();
		setLoading(false);
	}, []);
	const ordersGet = async () => {
		const transactions = await Orders.get.feedback().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (transactions === undefined) return;
		setOrders(transactions);
		setOrdersAll(transactions);
	};
	const orderShow = (order) => show(order);
	const show = (order) => {
		setId(order?._id||null);
		setAnswer(order?.feedback.answer||null);
		setOrder(order);
	};
	const cancel = () => {
		orderShow(null);
		modalChangeStatusHide();
	};
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setNumber(orders.find(f => f._id === id).number);
		modalChangeStatusOpen();
	};
	const feedbackChangeStatusAccept = (id) => feedbackChangeStatus(id);
	const feedbackChangeStatusModal = () => feedbackChangeStatus(id);
	const feedbackChangeStatus = (id) => {
		const order = orders.find(f => f._id === id);
		const status = order.feedback.status === orderFeedbackAction.ACCEPT ? orderFeedbackAction.REJECT : orderFeedbackAction.ACCEPT;
		Orders.feedback.status(id, status).then((res) => {
			successShow(res.message);
			ordersGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	};
	const searchOrder = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setOrders(ordersAll);
		else setOrders(ordersAll.filter(f => f.client?.name.toLowerCase().includes(search.toLowerCase())
			|| f.number.toString().includes(search.toLowerCase())
			|| f.feedback.comment.toLowerCase().includes(search.toLowerCase())
			|| f.client.phone.includes(search.toLowerCase())));
		setSearch(search);
	}
	const statusGet = (v) => <div className={`status${v.feedback.rating>3?1:0}`}>
		<div className="status-point"></div>{v.feedback.rating}
	</div>;
	const goto = (link) => history.push(link);
	const feedbackViewSet = (id) => {
		Orders.feedback.view(id).catch((ex) => console.log(666, ex));
		return null;
	};
	const massReject = async (ids) => {
		for (const v of ids) {
			await Orders.feedback.status(v, orderFeedbackAction.REJECT).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		ordersGet();
		cancel();
		return true;
	};
	const massAccept = async (ids) => {
		for (const v of ids) {
			await Orders.feedback.status(v, orderFeedbackAction.ACCEPT).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		ordersGet();
		cancel();
		return true;
	};
	const profileShow = (v) => goto(`/books/client/${v.client.id}`);
	const clientOrderShow = (v) => goto(`/order/${v._id}`);
	const answerAdd = (e) => {
		const answer = e.target.value;
		if (Utils.empty(answer)) return;
		Orders.feedback.answer(id, {answer,action:orderFeedbackAction.ACCEPT}).then(() => ordersGet());
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Отзывы, рейтинг"
							type="marketing"
							data={orders}
							id={id}
							search={search}
							rows={[
								{title:'Дата',field:'createdAt',func:(v) => moment(v.createdAt).format('DD.MM.YYYY HH:mm')},
								{title:'Заказ',field:'number'},
								{title:'Клиент',field:'client',sort:'client.name',func:(v) => v.client.name},
								{title:'Статус',field:'status',sort:'feedback.status',func:(v) => orderFeedbackActionName[v.feedback?.status]},
								{title:'Оценка',field:'status',class:'status status-small',sort:'feedback.rating',func:statusGet}
							]}
							rowsShort={['createdAt','status']}
							rowControlItems={[
								{title:'Перейти в заказ',class:'receipt',action:(v) => clientOrderShow(v)},
								{title:'Профиль клиента',class:'profile',action:(v) => profileShow(v)},
								{title:(v) => v.feedback.status!==orderFeedbackAction.ACCEPT?'Одобрить':'Отклонить',class:(v) => v.feedback.status!==orderFeedbackAction.ACCEPT?'check':'uncheck',action:(v) => v.feedback.status !== orderFeedbackAction.ACCEPT ? feedbackChangeStatusAccept(v._id) : modalChangeStatusShow(v._id)}
							]}
							footerItems={[
								{title:'Одобрить',action:massAccept,confirm:true},
								{title:'Отклонить',action:massReject,confirm:true}
							]}
							empty={<>Здесь появятся заказы<br/>с отзывами или рейтингом</>}
							contentShowTitle={`№${order?.number} от ${moment(order?.createdAt).format('DD.MM.YYYY HH:mm')}`}
							contentShow={order ?
								<>
									<h4>Отзыв</h4>
									<div className="product-view-row product-view-row-simple">
										{order?.feedback.comment||'–'}
									</div>
									{order?.feedback.comment ?
										<div className="feedback-answer">
											<h4>Ответ на отзыв</h4>
											<div className="product-edit-row">
												<textarea placeholder="Комментарий" className="lite" onBlur={answerAdd}>{answer}</textarea>
											</div>
										</div> : null}
									<h4>Оценка</h4>
									<div className="product-view-row product-view-row-simple">
										★ {order?.feedback.rating}
									</div>
									<Accordion title="Состав заказа" info={order?.products.map(v => v.name).join(', ')}>
										<div className="product-view-row product-view-row-simple">
											{order?.products.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
												{v.name}
												<span>{v.quantity} шт.</span>
											</div>)}
											<div className="product-edit-row">
												<div className="product-edit-btn" onClick={() => clientOrderShow(order)}>Перейти в заказ</div>
											</div>
										</div>
									</Accordion>
									<Accordion title="Данные клиента" info={order?.client.name}>
										<div className="product-view-row product-view-row-simple">
											{order?.client.name ? <>{order?.client.name}, </> : null} {Utils.phoneFormatter(order?.client.phone)}
											<div className="product-edit-row">
												<div className="product-edit-btn" onClick={() => profileShow(order)}>Профиль клиента</div>
											</div>
										</div>
									</Accordion>
									{feedbackViewSet(id)}

									{order?.feedback.users.length ?
										<Accordion title="Реакции">
											{order?.feedback.users.map((v,i) => <div key={i} className="product-view-row product-view-row-simple">
												{orderFeedbackActionName[v.action]} {moment(v.date).format('DD.MM.YYYY HH:mm')} • {v.name}
											</div>)}
										</Accordion> : null}

								</> : null
							}
							onSearch={searchOrder}
							onShow={orderShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отклонить отзыв</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отклонить отзыв клиента по заказу <b>{number}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={feedbackChangeStatusModal} className="btn-accept btn-delete">Отклонить</button>
					</div>
				</div>
			</ModalChangeStatus>
		</>
	);
};

export default FeedbacksScreen;