import React, {useEffect,useState} from 'react';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Empty from '../../../Components/Empty';
import Error from '../../../Components/Error';
import SideMenu from '../../../Components/SideMenu';
import Setting from '../../../Components/Setting';

// models
import {Settings} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { settingTarget } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgMenuPoints from './Images/menu-points.svg';

// start
const MessagesScreen = () => {
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [settings, setSettings] = useState([]);
	const [settingsAll, setSettingsAll] = useState([]);
	const [setting, setSetting] = useState(null);
	const [id, setId] = useState(null);
	const [search, setSearch] = useState(null);
	const [controlId, setControlId] = useState(null);
	const [isShow, setIsShow] = useState(false);
	const [isEditShow, setIsEditShow] = useState(false);
	const [isError, setIsError] = useState(false);
	const target = settingTarget.MESSAGES, targetUrl = 'messages';
	useEffect(async () => {
		const dataGet = async () => {
			cancel();
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await settingsGet(target);
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const settingsGet = async (target) => {
		const settings = await Settings.get.target(target).catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (settings === undefined) return;
		setSettings(settings);
		setSettingsAll(settings);
	};
	const settingEdit = (e, setting) => {
		e?.stopPropagation();
		setting = setting || settings.find(f => f._id === id);
		show(setting);
		setIsShow(false);
		setIsEditShow(true);
	};
	const settingShow = (setting) => {
		show(setting);
		setIsEditShow(false);
		setIsShow(true);
	};
	const show = (setting) => {
		setControlId(null);
		setId(setting?._id||null);
		if (setting) {
			setting.partnerId = partnerId;
			setting.targetUrl = targetUrl;
		}
		setSetting(setting ? {...setting} : null);
	};
	const cancel = () => {
		settingShow(null);
		setIsEditShow(false);
		setIsShow(false);
	};
	const searchSetting = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setSettings(settingsAll);
		else setSettings(settingsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
		setSearch(search);
	};
	const settingControlSelect = (e, id) => {
		e.stopPropagation();
		if (id === controlId) setControlId(null);
		else setControlId(id);
	};
	const update = async () => {
		await settingsGet(target);
		cancel();
	};
	return <Template click={() => setControlId(null)}>
			{loading ? <Loader /> :
				(isError ? <Error /> :
					<div className="components-container">
						<div className="categories-container">
							<SideMenu title="Уведомления" type="account" allHide={true} />
							<div className="products-list">
								<div className="products-list-controls">
									<input type="search" placeholder="Поиск" className="search search-single" onChange={searchSetting} value={search} />
								</div>
								<div className="container-inner">
									<table className="items">
										<thead>
											<tr>
												<th>Название</th>
												<th className="control"></th>
											</tr>
										</thead>
										<tbody>
											{settings.map((v,i) => <tr key={i} onClick={() => settingShow(v)} className={v._id===id?'row-selected':''}>
												<td>{v.name}</td>
												<td className="control">
													<img src={imgMenuPoints} alt="" onClick={(e) => settingControlSelect(e, v._id)} />
													{controlId === v._id ?
															<div className="control-block">
																<div className="edit" onClick={(e) => settingEdit(e, v)}>Редактировать</div>
															</div>
														: null}
												</td>
											</tr>)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						{isShow ? <Setting setting={setting} isShow={true} onEdit={(s) => settingEdit(null, s)} onUpdate={update} /> : null}
						{isEditShow ? <Setting setting={setting} isEditShow={true} onUpdate={update} /> : null}
						{isShow || isEditShow ? null : <Empty image={'settings'} text={<>Для редактирования настройки,<br />выберите одину из списка</>} />}
					</div>
				)
			}
		</Template>;
};

export default MessagesScreen;