import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get	= async ()		=> await Http.request('accountchecklists');

const set	= async (step)	=> await Http.request(`accountchecklist/${step}`, httpMethods.PATCH);


export {
	get,
	set
};