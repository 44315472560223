import React, {useEffect,useState} from 'react';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// components
import Template from '../../../Components/Template';
import Loader from '../../../Components/Loader';
import Error from '../../../Components/Error';
import {useInput} from '../../../Components/Hooks/Input';
import Alert,{errorShow,successShow} from '../../../Components/Alert';
import Layout from '../../../Components/Layout';
import PointsSelect from '../../../Components/PointsSelect';

// models
import {AccountCheckLists, Points, Products, PromoActions, Promocodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import Utils from '../../../Globals/Utils';

// constants
import { API, commonStatus, commonStatusName, measureTypeName } from '../../../Globals/Constants';

// styles
import './styles.css';

// images
import imgClose from './Images/close.svg';
import imgCheckOff from './Images/checkbox-off.svg';
import imgCheckOn from './Images/checkbox-on.svg';
import imgNoImage from './Images/no-image.svg';

// start
const ActionsScreen = () => {
	const [ModalChangeStatus, modalChangeStatusOpen, modalChangeStatusClose] = useModal('root');
	const [ModalProducts, modalProductsOpen, modalProductsClose] = useModal('root');
	const [ModalPromocodes, modalPromocodesOpen, modalPromocodesClose] = useModal('root');
	const [loading, setLoading] = useState(true);
	const [partnerId, setPartnerId] = useState(null);
	const [points, setPoints] = useState([]);
	const [promoactions, setPromoactions] = useState(null);
	const [promoactionsAll, setPromoactionsAll] = useState([]);
	const [id, setId] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:content, setValue:setContent, bind:bindContent} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	const [isMainShow, setIsMainShow] = useState(false);
	const [isFirstOrder, setIsFirstOrder] = useState(false);
	const [products, setProducts] = useState([]);
	const [productsAll, setProductsAll] = useState([]);
	const [promocodes, setPromocodes] = useState([]);
	const [promocodesAll, setPromocodesAll] = useState([]);
	const [promocodeId, setPromocodeId] = useState(null);
	const [productsSelected, setProductsSelected] = useState([]);
	const [promoactionPoints, setPromoactionPoints] = useState([]);
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [search, setSearch] = useState(null);
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [isError, setIsError] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			await pointsGet();
			await promocodesGet();
			await productsGet();
			await promoactionsGet();
			const point = ls('point');
			setPartnerId(point.partnerId);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const promoactionsGet = async () => {
		const promoactions = await PromoActions.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promoactions === undefined) return;
		setPromoactions(promoactions);
		setPromoactionsAll(promoactions);
	};
	const productsGet = async () => {
		const products = await Products.get.all().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (products === undefined) return;
		setProducts(products);
		setProductsAll(products);
	};
	const promocodesGet = async () => {
		const promocodes = await Promocodes.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (promocodes === undefined) return;
		setPromocodes(promocodes);
		setPromocodesAll(promocodes);
	};
	const pointsGet = async () => {
		const points = await Points.get.get().catch((ex) => {
			console.log(666, ex);
			setIsError(true);
		});
		if (points === undefined) return;
		setPoints(points);
	};
	const promoactionAdd = () => show();
	const promoactionEdit = (promoaction) => {
		promoaction = promoaction || promoactions.find(f => f._id === id);
		show(promoaction);
	};
	const promoactionShow = (promoaction) => show(promoaction);
	const show = (promoaction) => {
		setId(promoaction?._id||null);
		setName(promoaction?.name||'');
		setDescription(promoaction?.description||'');
		setContent(promoaction?.content||'');
		setSortOrder(promoaction?.options.sortOrder||'');
		setIsMainShow(promoaction?.options.isMainShow||false);
		setIsFirstOrder(promoaction?.options.isFirstOrder||false);
		setProductsSelected(promoaction?.products||[]);
		setPromocodeId(promoaction?.promocode?.id||null);
		setImage(promoaction ? imageUrlGet(partnerId, promoaction._id, promoaction.image) : null);
		setImageName(promoaction?.image||null);
		setPromoactionPoints(promoaction ? promoaction.points : points.map(v => ({id:v._id,name:v.name})));
		setStatus(promoaction?promoaction.status:commonStatus.ACTIVE);
	};
	const cancel = () => {
		promoactionShow(null);
		modalChangeStatusHide();
		modalProductsClose();
		modalPromocodesClose();
		setProducts(productsAll);
		setPromocodesAll(promocodesAll);
	}
	const save = async () => {
		const data = {
			points:promoactionPoints,
			name:name,
			description:description,
			content:content,
			products:productsSelected.map(f => f.id),
			promocodeId:promocodeId,
			isFirstOrder:isFirstOrder,
			isMainShow:isMainShow,
			sortOrder:sortOrder,
			status:status
		};
		if (Utils.empty(data.name)) {
			errorShow('Необходимо заполнить название акции');
			return;
		}
		if (data.points.length === 0) {
			errorShow('Необходимо выбрать филиалы');
			return;
		}
		const res = id ?
				await PromoActions.update(id, data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				})
			:
				await PromoActions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
		if (res) {
			if (image) imageUpload(id||res.id);
			checkListUpdate();
			successShow(res.message);
			promoactionsGet();
			cancel();
			return true;
		}
	};
	const checkListUpdate = async () => {
		const step = 8;
		await AccountCheckLists.set(step).catch((ex) => console.log(666, ex));
	};
	const imageUpload = async (id) => {
		const data = imagePrepare(image);
		if (data) {
			const type = Utils.imageExtGet(image);
			PromoActions.image.add(id, {data,type}).catch((ex) => console.log(666, ex));
		}
	};
	const imagePrepare = (image) => Utils.isImage(image) ? image.replace(/^.*,/gi,'') : null;
	const searchPromoaction = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromoactions(promoactionsAll);
		else setPromoactions(promoactionsAll.filter(f => f.name?.toLowerCase().includes(search?.toLowerCase()) || f.code?.toLowerCase().includes(search?.toLowerCase())));
		setSearch(search);
	}
	const handleStatus = (e) => setStatus(parseInt(e.target.value));
	const promoactionChangeStatusOn = (id) => promoactionChangeStatus(id);
	const promoactionChangeStatusModal = () => promoactionChangeStatus(id);
	const promoactionChangeStatus = (id) => {
		const promoaction = promoactions.find(f => f._id === id);
		const status = promoaction.status === commonStatus.ACTIVE ? commonStatus.IN_ACTIVE : commonStatus.ACTIVE;
		PromoActions.status(id, {status}).then((res) => {
			successShow(res.message);
			promoactionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
	}
	const modalChangeStatusHide = () => modalChangeStatusClose();
	const modalChangeStatusShow = (id) => {
		setId(id);
		setName(promoactions.find(f => f._id === id).name);
		modalChangeStatusOpen();
	}
	const promoactionDelete = (id) => {
		PromoActions.remove(id).then((res) => {
			successShow(res.message);
			promoactionsGet();
		}).catch(ex => {
			console.log(666, ex);
			errorShow();
		});
		cancel();
		return true;
	}
	const imageUrlGet = (partnerId, id, filename) => `${API.assets}partners/${partnerId}/actions/${id}/${filename}`;
	const imageError = (e) => {
		e.target.src = imgNoImage;
		e.target.className = 'image image-error';
	};
	const imageDelete = () => setImage(null);
	const handleImage = (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
		const reader = new FileReader();
		reader.onload = (r) => setImage(r.target.result);
		reader.readAsDataURL(file);
	};
	const productsShow = () => modalProductsOpen();
	const productSelect = (product) => {
		const i = productsSelected.findIndex(f => f.id === product._id);
		if (i === -1) setProductsSelected([...productsSelected, product]);
		else setProductsSelected(productsSelected.filter(f => f.id !== product._id));
	};
	const searchProduct = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setProducts(productsAll);
		else setProducts(productsAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const productAdd = () => {
		modalProductsClose();
		setProducts(productsAll);
	};
	const promocodeShow = () => modalPromocodesOpen();
	const promocodeSelect = (promocode) => {
		if (promocodeId === promocode._id) setPromocodeId(null);
		else setPromocodeId(promocode._id);
	};
	const searchPromocode = (e) => {
		const search = e.target.value;
		if (Utils.empty(search)) setPromocodes(promocodesAll);
		else setPromocodes(promocodesAll.filter(f => f.name.toLowerCase().includes(search.toLowerCase())));
	};
	const promocodeAdd = () => {
		modalPromocodesClose();
		setPromocodes(promocodesAll);
	};
	const massCopy = async (ids) => {
		for (const v of ids) {
			const s = promoactions.find(f => f._id === v);
			if (s) {
				const data = {
					name:`${s.name} (копия)`,
					description:s.description,
					content:s.content,
					products:s.products,
					promocodeId:s.promocode.id,
					isFirstOrder:s.options.isFirstOrder,
					isMainShow:s.options.isMainShow,
					sortOrder:s.options.sortOrder,
					status:s.status
				};
				const res = await PromoActions.add(data).catch((ex) => {
					console.log(666, ex);
					errorShow();
				});
				if (res) PromoActions.image.copy(s._id, res.id).catch((ex) => console.log(666, ex));
			}
		};
		successShow('Операция прошла успешно');
		promoactionsGet();
		cancel();
		return true;
	};
	const massDelete = async (ids) => {
		for (const v of ids) {
			await PromoActions.remove(v).catch((ex) => {
				console.log(666, ex);
				errorShow();
			});
		};
		successShow('Операция прошла успешно');
		promoactionsGet();
		cancel();
		return true;
	};
	const statusGet = (v) => <div className={`status${v.status===commonStatus.ACTIVE?v.status:0}`}>
		<div className="status-point"></div>
	</div>;
	const pointSelect = (point) => {
		const p = promoactionPoints.find(f => f.id === point._id);
		if (p) setPromoactionPoints(promoactionPoints.filter(f => f.id !== point._id));
		else setPromoactionPoints([...promoactionPoints, {id:point._id,name:point.name}]);
	};
	return (
		<>
			<Template>
				{loading ? <Loader /> :
					(isError ? <Error /> :
						<Layout
							title="Акции"
							type="marketing"
							data={promoactions}
							id={id}
							search={search}
							rows={[
								{title:'Название',field:'name'},
								{title:'Статус',field:'status',class:'status status-small',func:statusGet}
							]}
							rowControlItems={[
								{title:(v) => v.status===commonStatus.ACTIVE?'Отключить':'Включить',class:(v) => v.status===commonStatus.ACTIVE?'inactive':'active',action:(v) => v.status === commonStatus.ACTIVE ? modalChangeStatusShow(v._id) : promoactionChangeStatusOn(v._id)}
							]}
							footerItems={[
								{title:'Сделать копию',action:massCopy},
								{title:'Удалить',action:massDelete,confirm:true}
							]}
							empty={<>Добавьте первую<br/>акцию</>}
							contentShowTitle={name}
							contentShow={<>
								{description ? 
									<div className="product-view-row-simple">
										{description}
									</div> : null}
								<PointsSelect isShow={true} selected={promoactionPoints} />
								<h4>Фотография</h4>
								<div className="product-view-row product-view-row-simple">
									<img src={imageUrlGet(partnerId, id, imageName)} alt="" onError={imageError} className="image" />
								</div>
								<h4>Свойства</h4>
								<div className="product-view-row product-view-row-oneline">
									<span>Показывать на главной</span>
									<div>{isMainShow ? 'Да' : 'Нет'}</div>
								</div>
								<div className="product-view-row product-view-row-oneline">
									<span>Действует на первый заказ</span>
									<div>{isFirstOrder ? 'Да' : 'Нет'}</div>
								</div>
								{productsSelected.length ?
									<>
										<h4>Выбранные позиции</h4>
										{productsSelected.map((v,i) => <div key={i} className="product-view-row product-view-row-oneline">
											<span>{v.name}</span>
											<div>{v.price} ₽</div>
										</div>)}
									</> : null}
								{promocodeId ?
									<>
										<h4>Промокод</h4>
										<div className="product-view-row product-view-row-oneline">
											{promocodes.find(f => f._id === promocodeId).name}
										</div>
									</> : null}
							</>}
							contentEdit={<>
								<div className="product-edit-row">
									<input type="text" {...bindName} placeholder="Название" className="input-title" autoFocus={true} required />
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Краткое описание" {...bindDescription} className="lite"></textarea>
								</div>
								<div className="product-edit-row">
									<textarea placeholder="Полное описание" {...bindContent}></textarea>
								</div>
								<PointsSelect isEdit={true} points={points} selected={promoactionPoints} onSelect={pointSelect} />
								<h4>Фотография</h4>
								<div className="product-edit-row">
									{image ?
											<div className="product-edit-images-wide">
												<div className="product-edit-image">
													<img src={image} alt="" onError={imageError} className="image" />
													<div className="delete" onClick={imageDelete}></div>
												</div>
											</div>
										: null}
									<label>
										<div className="product-edit-btn">
											Добавить фото
											<input type="file" accept="image/jpeg,image/png,image/webp" onChange={handleImage} />
										</div>
									</label>
								</div>
								<h4>Позиции</h4>
								<div className="product-edit-row">
									{productsSelected.length ?
											<div className="product-edit-components">
												{productsSelected.map((v,i) => <div key={i} className="product-edit-component">
													{v.name}
													<img src={imgClose} alt="" className="close-icon" onClick={() => setProductsSelected(productsSelected.filter(f => f._id !== v._id))} />
												</div>)}
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => productsShow()}>Добавить товар</div>
								</div>
								<h4>Промокод</h4>
								<div className="product-edit-row">
									{promocodeId ?
											<div className="product-edit-components">
												<div className="product-edit-component">
													{promocodes.find(f => f._id === promocodeId).name}
													<img src={imgClose} alt="" onClick={() => setPromocodeId(null)} />
												</div>
											</div>
										: null
									}
									<div className="product-edit-btn" onClick={() => promocodeShow()}>Добавить промокод</div>
								</div>
								<h4>Дополнительные параметры</h4>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Показывать на главной</label>
									<img src={isMainShow ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsMainShow(!isMainShow)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label className="label-middle">Первый заказ</label>
									<img src={isFirstOrder ? imgCheckOn : imgCheckOff} alt="" onClick={() => setIsFirstOrder(!isFirstOrder)} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="sortOrder" className="label-middle">Порядок сортировки</label>
									<input id="sortOrder" {...bindSortOrder} type="text" placeholder="0" maxLength={2} />
								</div>
								<div className="product-edit-row product-edit-row-oneline">
									<label htmlFor="status">Статус</label>
									<div className="select select-middle">
										<select id="status" onChange={handleStatus} value={status} required>
											<option value=""></option>
											{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</div>
								</div>
							</>}
							onDelete={promoactionDelete}
							onEdit={promoactionEdit}
							onAdd={promoactionAdd}
							onSave={save}
							onSearch={searchPromoaction}
							onShow={promoactionShow}
							onClose={cancel}
						/>
					)
				}
			</Template>
			<Alert />
			<ModalChangeStatus>
				<div className="modal">
					<div className="header">
						<h4>Отключить акцию</h4>
						<img src={imgClose} alt="" onClick={modalChangeStatusHide} className="btn-close" />
					</div>
					<p>Вы уверены что хотите отключить <b>{name}?</b></p>
					<div className="buttons">
						<button type="button" onClick={modalChangeStatusHide} className="btn-cancel">Отменить</button>
						<button type="button" onClick={promoactionChangeStatusModal} className="btn-accept btn-delete">Отключить</button>
					</div>
				</div>
			</ModalChangeStatus>
			<ModalProducts>
				<div className="modal modal-products">
					<div className="header">
						<h4>Добавить позицию</h4>
						<img src={imgClose} alt="" onClick={modalProductsClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchProduct} autoFocus={true} />
					<div className="products">
						<div className="list">
							{products.length ?
									products.map((v,i) => <div key={i} className="product">
										<div>
											{v.name}
											<span>{v.prices.priceFull} ₽ • {v.properties.weight} {measureTypeName[v.properties.measureType]}</span>
										</div>
										<img src={productsSelected.find(f => f._id === v._id) ? imgCheckOn : imgCheckOff} alt="" onClick={() => productSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={productAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalProducts>
			<ModalPromocodes>
				<div className="modal modal-products">
					<div className="header">
						<h4>Выбрать промокод</h4>
						<img src={imgClose} alt="" onClick={modalPromocodesClose} className="btn-close" />
					</div>
					<input type="search" placeholder="Поиск" className="search" onChange={searchPromocode} autoFocus={true} />
					<div className="products">
						<div className="list">
							{promocodes.length ?
									promocodes.map((v,i) => <div key={i} className="product">
										<div>{v.name}</div>
										<img src={promocodeId === v._id ? imgCheckOn : imgCheckOff} alt="" onClick={() => promocodeSelect(v)} />
									</div>)
								:
									<div className="product-empty">
										<div>
											Ничего не найдено
										</div>
									</div>
							}
						</div>
						<div className="buttons">
							<button type="button" onClick={promocodeAdd} className="btn-accept">Добавить</button>
						</div>
					</div>
				</div>
			</ModalPromocodes>
		</>
	);
};

export default ActionsScreen;