import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Alert,{errorShow} from '../../Components/Alert';

// models
import {Users} from '../../Models';

// helpers
import Utils from '../../Globals/Utils';

// styles
import './styles.css';

// images
import imgLogo from './Images/logo.svg';


// start
const NewPasswordScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [code, setCode] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);
	useEffect(async () => {
		const dataGet = async () => {
			const code = props.match.params.code;
			const user = await Users.getByCode(code).catch((ex) => {
				console.log(666, ex);
				window.location.href = '/login';
				return;
			});
			if (user) setCode(code);
		};
		await dataGet();
		setLoading(false);
	}, []);
	const next = async () => {
		if (Utils.empty(password)) {
			setPasswordError(true);
			return;
		}
		const data = {
			code:code,
			password:password
		};
		const res = await Users.reset(data).catch((ex) => {
			console.log(666, ex);
			return error(ex);
		});
		if (res) {
			setPasswordError(false);
			setIsSuccess(true);
		}
	};
	const handlePassword = (e) => setPassword(e.target.value);
	const error = (message) => {
		setPasswordError(true);
		errorShow(message.message);
	};
	return loading ? null : (
		<>
			<div className="newpassword-container">
				<img src={imgLogo} alt="" />
				{isSuccess ?
						<div className="success">
							<div>Пароль успешно изменен!</div>
							<Link to="/login">Перейти к авторизации</Link>
						</div>
					:
						<>
							<div className="form-column">
								<label>
									<input type="password" placeholder=" " iserror={`${passwordError!==undefined&&passwordError}`} value={password} onChange={handlePassword} className="input-panel" maxLength={32} />
									<span className="placeholder">Пароль</span>
								</label>
							</div>
							<div className="buttons">
								<button onClick={next} className="btn">Подтвердить</button>
								<div className="forgot">
									<span>Есть аккаунт?</span> <Link to="/login">Войти</Link>
								</div>
							</div>
						</>
				}
			</div>
			<Alert />
		</>
	);
}

export default NewPasswordScreen;